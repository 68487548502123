import { create } from 'zustand';

interface OauthState {
  clientId: string;
  redirectUri: string;
  state: string;
  loginHint: string;
  nonce: string;
  responseType: string;
  scope: string;
  relyingParty: string;
  display: string;

  setClientId: (clientId: string) => void;
  setRedirectUri: (redirectUri: string) => void;
  setOauthState: (state: string) => void;
  setLoginHint: (loginHint: string) => void;
  setNonce: (nonce: string) => void;
  setResponseType: (responseType: string) => void;
  setScope: (scope: string) => void;
  setRelyingParty: (scope: string) => void;
  setDisplay: (display: string) => void;
}

export const useOauthStore = create<OauthState>()((set) => ({
  clientId: '',
  state: '',
  redirectUri: '',
  loginHint: '',
  nonce: '',
  responseType: '',
  scope: '',
  relyingParty: '',
  display: '',

  setClientId: (clientId) => set(() => ({ clientId })),
  setRedirectUri: (redirectUri) => set(() => ({ redirectUri })),
  setOauthState: (state) => set(() => ({ state })),
  setLoginHint: (loginHint) => set(() => ({ loginHint })),
  setNonce: (nonce) => set(() => ({ nonce })),
  setResponseType: (responseType) => set(() => ({ responseType })),
  setScope: (scope) => set(() => ({ scope })),
  setRelyingParty: (relyingParty) => set(() => ({ relyingParty })),
  setDisplay: (display) => set({ display }),
}));
