import { CognitoUserSession, CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import CommonLoginView from './CustomLoginUI/CommonLoginView';
import FirstPasswordStep from './CustomLoginUI/FirstPasswordStep';
import ForgotPasswordStep from './CustomLoginUI/ForgotPasswordStep';
import MFAStep from './CustomLoginUI/MFAStep';
import TooManyFailedAuthStep from './CustomLoginUI/TooManyFailedAuthStep';
import UsernameStep from './CustomLoginUI/UsernameStep';
import { LOGIN_FORM_STATES, useLoginStore } from '../../context/loginContext';
import { useOauthStore } from '../../context/oauthContext';
import * as CupidAuthService from '../../services/cupidAuthService';
import { TOKEN_STATUSES, DISPLAY_PAGE, MANAGE_WEBAUTHN, WEBAUTHN_REGISTERED_USERNAME_KEY, DEVICE_WEBAUTHN_PROMPTS_COUNT_KEY, LAST_WEBAUTHN_PROMPT_TIMESTAMP_KEY } from '../../globals/constants';
import { doOauthRedirect, getUserEmail } from '../../globals/utils';
import { TimeoutSpinnerStep } from '../Default/CustomLoginUI/TimeoutSpinnerStep';
import LoggedInStep from '../Default/CustomLoginUI/LoggedInStep';
import WebauthnStep from './CustomLoginUI/WebauthnStep';
import BiometricEditCompleteStep from './CustomLoginUI/BiometricEditCompleteStep';
import { useLocation } from 'react-router-dom';

import FailoverOTPWebAuthN from '../Default/FailoverUI/FailoverOTPWebAuthN';
import { ATOZ_WEBAUTHN_PROMPT_INTERVAL, ATOZ_WEBAUTHN_PROMPT_QUOTA } from './constants';
import { isIdPrismHttpError } from '../../globals/errors';
import { IdPrismUser } from '../../globals/types';

const LOGIN_FORM_VIEWS: Map<LOGIN_FORM_STATES, React.JSX.Element> = new Map([
  [LOGIN_FORM_STATES.USERNAME_STATE, <UsernameStep key={LOGIN_FORM_STATES.USERNAME_STATE} />],
  [LOGIN_FORM_STATES.FORGOT_PASSWORD_STATE, <ForgotPasswordStep key={LOGIN_FORM_STATES.FORGOT_PASSWORD_STATE} />],
  [LOGIN_FORM_STATES.NEW_PASSWORD_FIRST_LOGIN_STATE, <FirstPasswordStep key={LOGIN_FORM_STATES.NEW_PASSWORD_FIRST_LOGIN_STATE} />],
  [LOGIN_FORM_STATES.MFA_STATE, <MFAStep key={LOGIN_FORM_STATES.MFA_STATE} />],
  [LOGIN_FORM_STATES.LOGGED_IN_STATE, <LoggedInStep key={LOGIN_FORM_STATES.LOGGED_IN_STATE} />],
  [LOGIN_FORM_STATES.TOO_MANY_FAILED_AUTH, <TooManyFailedAuthStep key={LOGIN_FORM_STATES.TOO_MANY_FAILED_AUTH}/>],
  [LOGIN_FORM_STATES.SPINNER, <TimeoutSpinnerStep key={LOGIN_FORM_STATES.SPINNER}/>],
  [LOGIN_FORM_STATES.BIOMETRICS_STATE, <WebauthnStep key={LOGIN_FORM_STATES.BIOMETRICS_STATE}/>],
  [LOGIN_FORM_STATES.ATOZ_ENABLE_WEBAUTHN_STATE, <WebauthnStep key={LOGIN_FORM_STATES.ATOZ_ENABLE_WEBAUTHN_STATE}/>],
  [LOGIN_FORM_STATES.ATOZ_DISABLE_WEBAUTHN_STATE, <WebauthnStep key={LOGIN_FORM_STATES.ATOZ_DISABLE_WEBAUTHN_STATE}/>],
  [LOGIN_FORM_STATES.ATOZ_WEBAUTHN_ENABLED_STATE, <BiometricEditCompleteStep key={LOGIN_FORM_STATES.ATOZ_WEBAUTHN_ENABLED_STATE}/>],
  [LOGIN_FORM_STATES.ATOZ_WEBAUTHN_DISABLED_STATE, <BiometricEditCompleteStep key={LOGIN_FORM_STATES.ATOZ_WEBAUTHN_DISABLED_STATE}/>],
  [LOGIN_FORM_STATES.FAILOVER_EMAIL_OTP, <FailoverOTPWebAuthN key={LOGIN_FORM_STATES.FAILOVER_EMAIL_OTP}/>],
  [LOGIN_FORM_STATES.FAILOVER_SMS_OTP, <FailoverOTPWebAuthN key={LOGIN_FORM_STATES.FAILOVER_SMS_OTP}/>]
]);

export default function Login() {
  // Oauth Variables passed in URL
  const loginHint = useOauthStore((state) => state.loginHint);
  const redirectUri = useOauthStore((state) => state.redirectUri);
  const state = useOauthStore((state) => state.state);
  const responseType = useOauthStore((state) => state.responseType);
  const nonce = useOauthStore((state) => state.nonce);
  const scope = useOauthStore((state) => state.scope);
  const clientId = useOauthStore((state) => state.clientId);
  const relyingParty = useOauthStore((state) => state.relyingParty);
  const display = useOauthStore((state) => state.display)

  const loginFormState = useLoginStore((state) => state.loginFormState);
  const cognitoUser = useLoginStore((state) => state.cognitoUser);
  const setLoginFormState = useLoginStore((state) => state.setLoginFormState);
  const setCognitoUser = useLoginStore((state) => state.setCognitoUser);
  const setUsername = useLoginStore((state) => state.setUsername);
  const setPassword = useLoginStore((state) => state.setPassword);
  const setFirstTimeLogin = useLoginStore((state) => state.setFirstTimeLogin);
  const [viewContent, setViewContent] = useState(LOGIN_FORM_VIEWS.get(LOGIN_FORM_STATES.SPINNER)!);
  const setErrorRedirecting = useLoginStore((state) => state.setErrorRedirection);
  const setPromptRegisterWebauthn = useLoginStore((state) => state.setPromptRegisterWebauthn);
  const setWebauthnRegisteredOnDevice = useLoginStore((state) => state.setWebauthnRegisteredOnDevice);
  const webauthnRegisteredOnDevice = useLoginStore((state) => state.webauthnRegisteredOnDevice);
  const username = useLoginStore((state) => state.username);
  const webauthnPrompted = useLoginStore((state) => state.webauthnPrompted);
  
  const location = useLocation();

  useEffect(() => {
    const getSession = async () => {
      if (cognitoUser) {
        const session = await Auth.currentSession();

        // Refresh user session
        cognitoUser.refreshSession(session.getRefreshToken(), async (err: Error, newSession: CognitoUserSession) => {
          if (err) {
            console.log('Unable to refresh Token', err);
            setLoginFormState(LOGIN_FORM_STATES.USERNAME_STATE)
            return;
          }

          if (location.pathname === MANAGE_WEBAUTHN) {
            setLoginFormState(LOGIN_FORM_STATES.ATOZ_ENABLE_WEBAUTHN_STATE);
          } else {
            const idToken = newSession.getIdToken().getJwtToken();
            const expiresIn = newSession.getAccessToken().getExpiration().toString();
            if (relyingParty && clientId) {
              // Call to idprism auth endpoint
              try {
                const authResponse = await CupidAuthService.authorize({ idToken, keepUserSignedIn: true, relyingParty, clientId });
                  if (expiresIn && state && redirectUri) {
                    doOauthRedirect(
                      redirectUri,
                      authResponse.authCode, //idprism token
                      expiresIn,
                      state,
                      responseType,
                      nonce,
                      scope,
                      clientId
                    );
                    setLoginFormState(LOGIN_FORM_STATES.LOGGED_IN_STATE);
                    setPassword('');
                  }
                } catch(err) {
                  setPassword('');
                    // If session is expired then return to first login step
                  if(isIdPrismHttpError(err)) {
                    if(err.httpError.response?.data?.status === TOKEN_STATUSES.INVALID_SESSION) {
                      const userEmail = await getUserEmail(cognitoUser);
                      if (userEmail) setUsername(userEmail);
                      setLoginFormState(LOGIN_FORM_STATES.USERNAME_STATE);
                    }
                  }
                  setErrorRedirecting(true)
                  console.error('ERROR Logging In', err);
                }
            } else {
              // redirect to a logged in page
              setLoginFormState(LOGIN_FORM_STATES.LOGGED_IN_STATE);
            }
          }
        });
      }
    };
    getSession();
  }, [cognitoUser]);

  useEffect(() => {
    if (webauthnPrompted) {
      const deviceWebauthnPrompts = +(localStorage.getItem(DEVICE_WEBAUTHN_PROMPTS_COUNT_KEY + username) ?? '0');
      localStorage.setItem(DEVICE_WEBAUTHN_PROMPTS_COUNT_KEY + username, JSON.stringify(deviceWebauthnPrompts + 1));
      localStorage.setItem(LAST_WEBAUTHN_PROMPT_TIMESTAMP_KEY + username, JSON.stringify(Date.now()));
    }
  }, [webauthnPrompted])

  useEffect(() => {
    const deviceWebauthnPrompts = +(localStorage.getItem(DEVICE_WEBAUTHN_PROMPTS_COUNT_KEY + username) ?? '0');
    const lastWebauthnPromptTimestamp = +(localStorage.getItem(LAST_WEBAUTHN_PROMPT_TIMESTAMP_KEY + username) ?? '0');
    // In the absence of any data, prompt by default
    setPromptRegisterWebauthn(true);
    if (deviceWebauthnPrompts > ATOZ_WEBAUTHN_PROMPT_QUOTA || Date.now() - (+lastWebauthnPromptTimestamp) < ATOZ_WEBAUTHN_PROMPT_INTERVAL) {
      // Maximum retry quota reached or enough time has not yet passed
      setPromptRegisterWebauthn(false); 
    }
  }, [username])

  useEffect(() => {
    const checkCurrentSession = async () => {
      let user: CognitoUser | undefined;
      try {
        user = await Auth.currentAuthenticatedUser();
      } catch (err) { /* empty */ }
        if (user) {
          setCognitoUser(user as IdPrismUser);
        } else {
          if (loginHint && display === DISPLAY_PAGE) {
            setFirstTimeLogin(true);
            setPromptRegisterWebauthn(true);
          }
          setLoginFormState(LOGIN_FORM_STATES.USERNAME_STATE);
        }
    };

    const webauthnRegisteredUsername = localStorage.getItem(WEBAUTHN_REGISTERED_USERNAME_KEY);
    if (webauthnRegisteredUsername) {
      setUsername(webauthnRegisteredUsername)
      setWebauthnRegisteredOnDevice(true);
    }

    checkCurrentSession();
  }, []);

  useEffect(() => {
    if (loginHint) {
      setUsername(loginHint);
    }
  }, [loginHint]);

  useEffect(() => {
    if (webauthnRegisteredOnDevice) {
      localStorage.setItem(WEBAUTHN_REGISTERED_USERNAME_KEY, username);
    } else {
      localStorage.removeItem(WEBAUTHN_REGISTERED_USERNAME_KEY);
    }
  }, [webauthnRegisteredOnDevice])
  
  // When the loginFormState is updated we set the content to be rendered here
  useEffect(() => {
    let content = LOGIN_FORM_VIEWS.get(loginFormState);
    if (!content) {
      content = <UsernameStep />;
    }

    setViewContent(content);
  }, [loginFormState])

  return (
      <CommonLoginView content={viewContent} />
  );
}
