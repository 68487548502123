import { View } from '@amzn/stencil-react-components/esm/layout';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/esm/message-banner';
import { IconAlertTriangleFill } from '@amzn/stencil-react-components/esm/submodules/icons/icons';
import { useTranslation } from 'react-i18next';

export default function FailoverBanner() {
    const { t } = useTranslation();

    return (
        <View alignSelf='center' flex={5} minWidth='300px' maxWidth='400px'>
            <MessageBanner
                data-testid='failoverBanner'
                dismissButtonAltText={t('resources:failoverBannerAltText') || ''}
                icon={<IconAlertTriangleFill size={16} />}
                titleText={t('resources:failoverWarningHeader') || ''}
                type={MessageBannerType.Warning}
            >
                {t('resources:failoverWarningBody')}
            </MessageBanner>
        </View>
    );
}