import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/esm/spinner";
import { useEffect } from "react";
import { SPINNER_TIMEOUT } from "../../../globals/constants";
import { LOGIN_FORM_STATES, useLoginStore } from "../../../context/loginContext";
import { Auth } from "aws-amplify";

export function TimeoutSpinnerStep() {
    const setLoginFormState = useLoginStore((state) => state.setLoginFormState);
    const setErrorRedirecting = useLoginStore((state) => state.setErrorRedirection);

    useEffect(() => {
        const timeOut = setTimeout(() => {
            setErrorRedirecting(true);
            Auth.currentSession().then((session) => {
                if (session && session.isValid()) {
                    setLoginFormState(LOGIN_FORM_STATES.LOGGED_IN_STATE);
                } else {
                    setLoginFormState(LOGIN_FORM_STATES.USERNAME_STATE);
                }
            }).catch(() => {
                setLoginFormState(LOGIN_FORM_STATES.USERNAME_STATE);
            });
        }, SPINNER_TIMEOUT * 1000);

        // Stops the clock and prevents the timeout callback from running if the component gets unmounted
        return () => {
            clearTimeout(timeOut);
        }
    }, [])

    return (
        <Spinner data-testid='logged-in-spinner' size={SpinnerSize.Medium}/>
    )
}