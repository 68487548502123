import Axios, {AxiosError, AxiosInstance} from 'axios';
import { CUPID_API_BASE_URL, QUERY_PARAM_LABELS, REGIONAL_CUPID_API_BASE_URL } from '../globals/constants';
import { CredentialData, WebAuthNCredential } from '../globals/types';
import { IdPrismHttpError } from '../globals/errors';
import { useAxiosErrorStore } from '../context/axiosErrorContext';

const BEGIN_REGISTER_PATH = `/webauthn/beginregister`;
const COMPLETE_REGISTER_PATH = `/webauthn/completeregister`;
const DELETE_CREDENTIAL_PATH = `/webauthn/deletecredential`;
const LIST_CREDENTIALS_PATH = `/webauthn/listcredentials`;
const USER_POOL_PATH = '/webauthn/userpool';
const AUTH_ENDPOINT_PATH = `/oauth2/authorize`;
const STATUS_ENDPOINT_PATH = `/status`;


function createAxios(baseURL: string) {
    const axios = Axios.create({
        timeout: 5000,
        baseURL,
    })

    // TODO @sswyn - uncomment once CupidAuthService pipeline is deployed on 2024-08-01
    // axios.interceptors.request.use((config) => {
    //     const transactionId = uuid.v4();
    //     config.headers = config.headers || {};
    //     config.headers[REQUEST_ID_HEADER] = transactionId;
    //     return config;
    // });

    axios.interceptors.response.use((response) => response, (error: AxiosError) => {
        console.error(error);
        if (Axios.isAxiosError(error)) {
            const idprismError = new IdPrismHttpError({ axiosError: error })
            useAxiosErrorStore.getState().addError(idprismError);
            return Promise.reject(idprismError);
        }
        return Promise.reject(error);
    })
    return axios;
}

const axios = createAxios(CUPID_API_BASE_URL);
const regionalAxios = createAxios(REGIONAL_CUPID_API_BASE_URL);


export interface StatusRequestProps {
    primaryPoolId: string;
}

export interface StatusResponse {
    userPoolId?: string;
    appClientId?: string;
}

/**
 * Makes a call to the GET /status endpoint
 * @param userpoolId Cognito User Pool ID of the primary userpool
 * @returns If in a DR scenario, status will return Cognito pool information for the userpool to configure to. Otherwise, the response is empty
 */
export async function getStatus({ primaryPoolId }: StatusRequestProps) {
    const response = await axios.get<StatusResponse>(STATUS_ENDPOINT_PATH, {
        params: {
            [QUERY_PARAM_LABELS.PRIMARY_POOL_ID]: primaryPoolId
        }
    })
    return response.data;
}

export interface ListWebauthnCredentialsProps {
    token: string;
}

export interface CredentialResponse {
    credentials: WebAuthNCredential[]
}

export async function listWebauthnCredentials({ token }: ListWebauthnCredentialsProps) {
    const response = await axios.get<CredentialResponse>(LIST_CREDENTIALS_PATH, {
        headers: {
            Authorization: token,
            'Content-Type': 'application/json',
        },
    });
    return response.data;
}

export interface DeleteWebauthnCredentialProps {
    token: string;
    credentialId: string;
}

export interface DeleteWebauthnCredentialResponse {
    deleted: boolean,
    id?: string
}

export async function deleteWebauthnCredential({ token, credentialId }: DeleteWebauthnCredentialProps) {
    const response = await axios.delete<DeleteWebauthnCredentialResponse>(DELETE_CREDENTIAL_PATH, {
        headers: {
            Authorization: token,
            'Content-Type': 'application/json',
        },
        params: {
            id: credentialId
        }
    });
    return response.data;
}

export interface AuthorizeRequestProps {
    idToken: string,
    keepUserSignedIn: boolean,
    relyingParty: string,
    clientId: string
}

export interface AuthorizeResponse {
    status: string;
    message: string;
    authCode: string;
}

/**
 * Makes a call to the active/standby post authorization endpoint to retrieve the IdPrism auth code
 * @param request Request to make POST /Authorize OIDC endpoint
 * @param request.idToken Cognito ID token
 * @param request.keepUserSignedIn When false, controls session length by quickly signing the user out after successful authentication
 * @param request.relyingParty The relying party passed from federate to auth service
 * @param request.clientId The client id passed from federate to auth service
 * @returns A Response containing the IdPrism Auth code or a Status indicating why the auth code could not be provided
 */
export async function authorize({ idToken, keepUserSignedIn, relyingParty, clientId }: AuthorizeRequestProps) {
  return await executeAuthorize(axios, {idToken, keepUserSignedIn, clientId, relyingParty});
}

/**
 * Makes a call to the active/active post authorization endpoint to retrieve the IdPrism auth code
 * @param request Request to make POST /Authorize OIDC endpoint
 * @param request.idToken Cognito ID token
 * @param request.keepUserSignedIn When false, controls session length by quickly signing the user out after successful authentication
 * @param request.relyingParty The relying party passed from federate to auth service
 * @param request.clientId The client id passed from federate to auth service
 * @returns A Response containing the IdPrism Auth code or a Status indicating why the auth code could not be provided
 */
export async function authorizeViaRegionalEndpoint({ idToken, keepUserSignedIn, relyingParty, clientId }: AuthorizeRequestProps) {
    return await executeAuthorize(regionalAxios, {idToken, keepUserSignedIn, clientId, relyingParty});
}

async function executeAuthorize(
    axios: AxiosInstance,
    { idToken, keepUserSignedIn, relyingParty, clientId }: AuthorizeRequestProps
) {
    const data = {
        [QUERY_PARAM_LABELS.ID_TOKEN]: idToken,
        [QUERY_PARAM_LABELS.KEEP_USER_SIGNED_IN]: keepUserSignedIn.toString(),
        [QUERY_PARAM_LABELS.CLIENT_ID]: clientId,
        [QUERY_PARAM_LABELS.RELYING_PARTY]: relyingParty,
    };
    const response = await axios.post<AuthorizeResponse>(
        AUTH_ENDPOINT_PATH,
        data,
    );
    return response.data;
}

export interface BeginRegisterRequestProps {
    relyingPartyId: string;
    token: string;
}

export interface BeginRegisterResponse {
    session: {
        challenge: string;
        user_id: string;
    }
}

export async function beginRegister({ relyingPartyId, token }: BeginRegisterRequestProps) {
    const data = JSON.stringify({ relyingPartyId });
    const response = await axios.post(BEGIN_REGISTER_PATH, data, {
        headers: {
            Authorization: token,
            'Content-Type': 'application/json',
        },
    });
    return response.data;
}

export interface CompleteRegisterRequestProps {
    token: string;
    challenge: string;
    relyingPartyId: string;
    credentialData: CredentialData
    name?: string;
}

export interface CompleteRegisterResponse {
}

export async function completeRegister({ token, challenge, relyingPartyId, name, credentialData }: CompleteRegisterRequestProps) {

    const data = JSON.stringify({
        challenge,
        relyingPartyId,
        credentialData,
        name
    })
    const response = await axios.post<CompleteRegisterResponse>(COMPLETE_REGISTER_PATH, data, {
        headers: {
            Authorization: token,
            'Content-Type': 'application/json',
        },
    });
    return response.data;
}

export interface GetUserPoolRequest {
    primaryPoolId: string;
}

export interface GetUserPoolResponse {
    userPoolId: string;
    appClientId: string;
    region: string;
    isPrimary: boolean;
    userPoolProperties?: {
        isActiveActiveEnabled?: boolean;
        isAllowOtpInPrimaryRegion?: boolean;
    }
}

export async function getUserPool({primaryPoolId}: GetUserPoolRequest) {
    const response = await regionalAxios.get<GetUserPoolResponse>(USER_POOL_PATH, {
        params: {
            [QUERY_PARAM_LABELS.PRIMARY_POOL_ID]: primaryPoolId
        }
    });
    return response.data;
}
