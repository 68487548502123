import { create } from "zustand";
import { WebAuthNCredential } from "../globals/types";
import { Auth } from "aws-amplify";
import * as CupidAuthService from '../services/cupidAuthService';

interface CredentialState {
    loading: boolean,
    success: boolean,
    deleteSuccess: boolean,
    deleteError: boolean
    error: boolean,
    data: WebAuthNCredential[] | [],
    errorData: null | string,

    getListCredentials: () => void;
    deleteCredential: (id: string) => void;
}

const initialState = {
    deleteSuccess: false,
    deleteError: false,
    loading: false,
    success: false,
    error: false,
    data: [],
    errorData: '',
};

export const useCredentialManagementStore = create<CredentialState>()((set) => ({
    ...initialState,

    getListCredentials: async () => {
        set({ ...initialState, loading: true });
        try {
            const session = await Auth.currentSession();
            const response = await CupidAuthService.listWebauthnCredentials({ token: session.getIdToken().getJwtToken() });
            set({ ...initialState, success: true, data: response.credentials });
        /* eslint-disable @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            set({ ...initialState, error: true, errorData: error.message || 'Something went wrong' });            
        }
    },

    deleteCredential: async (credentialId: string) => {
        set({ ...initialState, loading: true });
        try {
            const session = await Auth.currentSession();
            const response = await CupidAuthService.deleteWebauthnCredential({ token: session.getIdToken().getJwtToken(), credentialId });
            if(response.deleted) {
                set({ ...initialState, deleteSuccess: true });
            } else {
                throw new Error('Failed to delete credential');
            }
        /* eslint-disable @typescript-eslint/no-explicit-any */
        } catch (error: any) {
            set({ ...initialState, deleteError: true, errorData: error?.message || 'Something went wrong'});
        }
    },
}));