import { Col, Row } from '@amzn/stencil-react-components/esm/layout';
import { Text } from '@amzn/stencil-react-components/esm/text';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/esm/button';
import { Card, CardStatus } from '@amzn/stencil-react-components/esm/card';
import { Modal } from '@amzn/stencil-react-components/esm/modal';
import { H3 } from '@amzn/stencil-react-components/esm/text';
import { useCredentialManagementStore } from '../../../context/credentialManagementContext';
import { WebAuthNCredential } from '../../../globals/types';
import { useTranslation } from 'react-i18next';

export interface DeleteCredentialModalProps {
    credential: WebAuthNCredential | null;
    setShowDeleteModal: (show: boolean) => void;
    showDeleteModal: boolean
}

export default function DeleteCredentialModal(props: DeleteCredentialModalProps) {
    const { t } = useTranslation();

    const { setShowDeleteModal, showDeleteModal } = props;
    const deleteCredential = useCredentialManagementStore(state => state.deleteCredential)
    const handleDeleteCredential = () => {
        if (props.credential) {
            deleteCredential(props.credential?.id);
        }
        setShowDeleteModal(false);
    }

    return (
        <>
            {props.credential &&
                <Modal isOpen={showDeleteModal} close={() => setShowDeleteModal(false)} >
                    <Card height={350} width={350} status={CardStatus.Warning} >
                        <Col width="100%" gridGap="S200">
                            <H3 data-testid='delete-credential-header'>{t('resources:deleteCredentialHeader')}</H3>
                            <Col gridGap="S300" height="100%" backgroundColor='neutral0'>
                                <Text style={{ whiteSpace: 'normal' }}>
                                    {t('resources:deleteCredentialMessage')}
                                </Text>
                            </Col>
                            <Col gridGap="S300" height="100%" backgroundColor='neutral0'>
                                <Text data-testid='credential-name-text' style={{ whiteSpace: 'normal' }}>
                                    {t('resources:credentialName')}: {props.credential.name ?? props.credential.id.substring(0, 5)}
                                </Text>
                            </Col>
                            <Row justifyContent="center">
                                <Button
                                    data-testid='confirm-delete-credential-button'
                                    isDestructive={true}
                                    variant={ButtonVariant.Secondary}
                                    onClick={() => handleDeleteCredential()}
                                >
                                    {t('resources:deleteCredentialButton')}
                                </Button>
                            </Row>
                        </Col>
                    </Card>
                </Modal>
            }
        </>
    );

}
