import { useEffect } from 'react';
import { Col } from '@amzn/stencil-react-components/esm/layout';
import { H3 } from '@amzn/stencil-react-components/esm/text';
import { Auth } from 'aws-amplify';
import { useOauthStore } from '../../context/oauthContext';
import { useTranslation } from 'react-i18next';

export default function Logout() {
  const { t } = useTranslation();
  const redirectUri = useOauthStore((state) => state.redirectUri);
  useEffect(() => {
    const performLogout = async () => {
      await Auth.signOut();
      if (redirectUri) {
        window.location.replace(redirectUri);
      }
    };
    performLogout();
  }, [redirectUri]);

  return (
      <Col
        minHeight='95vh'
        justifyContent='center'
        alignItems='center'
      >
        <H3>{t('resources:signedOut')}</H3>
      </Col>
  );
}
