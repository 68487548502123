import { useState, useEffect } from 'react';
import AndroidWebauthnSmall from '../assets/AndroidWebauthnSmall.svg';
import AppleWebauthnSmall from '../assets/AppleWebauthnSmall.svg';
import DefaultWebAuthnSmall from '../assets/DefaultWebauthnSmall.svg';
import AndroidBioMetricsLarge from '../assets/AndroidWebauthn.svg';
import AppleBioMetricsLarge from '../assets/AppleWebauthn.svg';
import DefaultWebAuthnLarge from '../assets/DefaultWebauthn.svg';
import { useTranslation } from 'react-i18next';

interface IconSets {
    [key: string]: {
        small: string;
        large: string;
    };
}

const IconSets: IconSets = {
    default: {
        small: DefaultWebAuthnSmall,
        large: DefaultWebAuthnLarge,
    },
    android: {
        small: AndroidWebauthnSmall,
        large: AndroidBioMetricsLarge,
    },
    apple: {
        small: AppleWebauthnSmall,
        large: AppleBioMetricsLarge,
    },
};

export enum WebauthnIconSize {
    SMALL = 'small',
    LARGE = 'large'
}

export const useWebauthnIcons = (iconSize: WebauthnIconSize = WebauthnIconSize.SMALL): string => {
    const [webauthnIcons, setWebauthnIcons] = useState(IconSets.default[`${iconSize}`]);

    useEffect(() => {
        const userAgent = navigator.userAgent;
        if (userAgent.includes('Android')) {
            setWebauthnIcons(IconSets.android[`${iconSize}`]);
        } else if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
            setWebauthnIcons(IconSets.apple[`${iconSize}`]);
        }
    }, [iconSize]);

    return webauthnIcons;
};

export const useWebauthnStrings = () => {
    const { t, i18n } = useTranslation();
    const [webauthnStrings, setWebauthnStrings] = useState({ face: t('resources:faceIdIOS'), touch: t('resources:touchIdIOS')});

    useEffect(() => {
        const webauthnStringsByDevice = {
            Android: {
                face: t('resources:faceIdAndroid'),
                touch: t('resources:touchIdAndroid')
            },
            IOS: {
                face: t('resources:faceIdIOS'),
                touch: t('resources:touchIdIOS')
            }
        };

        const userAgent = navigator.userAgent;
        if (userAgent.includes('Android')) {
            setWebauthnStrings(webauthnStringsByDevice.Android);
        } else {
            setWebauthnStrings(webauthnStringsByDevice.IOS);
        }
    }, [i18n.language]);

    return webauthnStrings;
}

export default useWebauthnIcons;
