import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/esm/spinner";
import { P } from "@amzn/stencil-react-components/esm/text";
import { t } from "i18next";
import LoadingOverlayWrapper from "react-loading-overlay-ts";

interface OverlayWrapperProps {
    children: JSX.Element | JSX.Element[];
    active: boolean;
}

export default function SpinnerOverlay(props: OverlayWrapperProps) {
  return (
    <LoadingOverlayWrapper
      {...props}
      spinner={<Spinner data-testid="overlay-spinner" size={SpinnerSize.Medium}/>}
      text={<P>{t('resources:verifying')}</P>}
      styles={{
        overlay: base => ({
          ...base,
          background: "rgba(255, 255, 255, 0.6)"
        }),
        content: base => ({
          ...base,
        })
      }}
    >
      {props.children}
    </LoadingOverlayWrapper>
  )
}