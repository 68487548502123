import React from "react"
import { useAxiosErrorStore} from "../context/axiosErrorContext";
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/esm/message-banner';
import { CustomerConfig } from "../globals/types";

export interface AxiosFlashBarProps {
    customerConfig: CustomerConfig;
}

export function AxiosFlashBar({customerConfig}: AxiosFlashBarProps) {
    const errors = useAxiosErrorStore(state => state.errors);
    const removeError = useAxiosErrorStore(state => state.removeError);

    // Will need sign-off from atoz team to remove this
    if(customerConfig?.template === 'atoz') {
        return <></>
    }

    const errorBars = errors.map((error, i) => {
        let errorMessage = error.message;
        if(error.transactionId) {
            errorMessage = `TransactionId: ${error.transactionId}. ${errorMessage}`;
        }
        return (
            <MessageBanner
                dataTestId="error-banner"
                type={MessageBannerType.Error}
                isDismissible={true}
                onDismissed={() => {removeError(error)}}
                key={i}
            >{errorMessage}</MessageBanner>
        )
    });

    return (
        <>
          {errorBars}
        </>
    )
}