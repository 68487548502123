import { Button, ButtonVariant } from '@amzn/stencil-react-components/esm/button';
import { Col, Row } from '@amzn/stencil-react-components/esm/layout';
import { H2, P, Text } from '@amzn/stencil-react-components/esm/text';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExclamationCircle from '../../../assets/exclamation-circle-small.svg';
import { LOGIN_FORM_STATES, useLoginStore } from '../../../context/loginContext';
import NameCredentialModal from './NameCredentialModal';
import { registerCredential } from '../../../globals/utils';
import useWebauthnIcons, { WebauthnIconSize } from '../../../hooks/webauthnHooks';

export default function WebauthnStep() {
    const { t } = useTranslation();
    const intermediateUser = useLoginStore((state) => state.intermediateUser);
    const setCognitoUser = useLoginStore((state) => state.setCognitoUser);
    const setLoginFormState = useLoginStore((state) => state.setLoginFormState);
    const webauthnIcon = useWebauthnIcons(WebauthnIconSize.LARGE)
    const [showErrorCollectingWebAuthNKey, setShowErrorCollectingWebAuthNKey] = useState(false);
    const setPromptRegisterWebauthn = useLoginStore((state) => state.setPromptRegisterWebauthn);
    const setShowSpinnerOverlay = useLoginStore((state) => state.setShowSpinnerOverlay);
    const [showWebAuthNNotSupportedError, setShowWebAuthnNotSupportedError] = useState(false);
    const [credentialName, setCredentialName] = useState('')
    const [showNameCredentialModal, setShowNameCredentialModal] = useState(false)

    const onClickEnable = async () => {
        setShowNameCredentialModal(true);
    }

    /*
        Once we've name the security key, we can proceed to registering the credentials via native webauthn api and our register credential API
    */
    useEffect(() => {
        const startRegisteringCredentials = async () => {
            if (!showNameCredentialModal && credentialName.length) {
                if (!window.PublicKeyCredential) { // Client not capable of webauthn
                    setShowWebAuthnNotSupportedError(true);
                }
                // call register apis
                if (intermediateUser) {
                    try {
                        setShowSpinnerOverlay(true);
                        const session = await Auth.currentSession();
                        await registerCredential(intermediateUser.getUsername(), session.getIdToken().getJwtToken(), credentialName);
                        setPromptRegisterWebauthn(false);
                        setCognitoUser(intermediateUser);
                        setShowSpinnerOverlay(false);
                        setCredentialName('')
                        setLoginFormState(LOGIN_FORM_STATES.SPINNER);
                    } catch (err) {
                        setShowErrorCollectingWebAuthNKey(true);
                        setShowSpinnerOverlay(false);
                        setCredentialName('')
                    }
                }
            }
        }
        startRegisteringCredentials();
    }, [showNameCredentialModal]);

    // TODO update for webauthn mandatory case
    const onClickNotNow = () => {
        if (intermediateUser) setCognitoUser(intermediateUser);
        setPromptRegisterWebauthn(false);
        setLoginFormState(LOGIN_FORM_STATES.SPINNER);
    }

    return (
        <>
            <NameCredentialModal credentialName={credentialName} setCredentialName={setCredentialName} showModal={showNameCredentialModal} setShowModal={setShowNameCredentialModal} />
            <H2 fontWeight="bold" id='webauthn-step-header'> {t('resources:enableWebAuthn')}</H2>
            <Col gridGap='S300'>
                <Text fontSize="T400">{t('resources:webauthnLoginInstructions')}</Text>
                <img width='auto' src={webauthnIcon} alt='Web Authn Icons' />
                {showErrorCollectingWebAuthNKey && (
                    <Row
                        gridGap='S200'
                        justifyContent='center'
                        alignItems='center' hidden={showErrorCollectingWebAuthNKey}>
                        <img width='auto' src={ExclamationCircle} alt='ExclamationCircle' />
                        <P fontSize="T200">{t('resources:errorCapturingWebauthn')}</P>
                    </Row>)}
                {showWebAuthNNotSupportedError && (
                    <Row
                        gridGap='S200'
                        justifyContent='center'
                        alignItems='center' hidden={showWebAuthNNotSupportedError}>
                        <img width='auto' src={ExclamationCircle} alt='ExclamationCircle' />
                        <P fontSize="T200">{t('resources:webauthnNotSupported')}</P>
                    </Row>)}
                <Button
                    data-testid="enable-webauthn-button"
                    onClick={() => onClickEnable()}
                    variant={ButtonVariant.Primary}
                >
                    {t('resources:enable')}
                </Button>
                <Button
                    data-testid="not-now-webauthn-button"
                    onClick={onClickNotNow}
                    variant={ButtonVariant.Secondary}
                >
                    {t('resources:notNow')}
                </Button>
            </Col>
        </>
    )
}