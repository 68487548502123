import { Resource } from 'i18next';
import Config from '../../../customer-config';
import './translations/CUPID-IdPrismCupidLogin-en-US.puff.json';
import './translations/CUPID-TPS-en-US.puff.json';
import './translations/CUPID-TaCS-en-US.puff.json';
import { DEFAULT_TEMPLATE_LABEL } from '../../../globals/constants';
import { getCustomerIdFromDomain } from '../../../globals/utils';
import { Locale } from '../../../globals/types';

export function createLangMap() {
    let locales: Locale[] = Config.translations[getCustomerIdFromDomain()];
    if (!locales) {
        locales = Config.translations[DEFAULT_TEMPLATE_LABEL];
    }
    const langMap = new Map();
    locales.map(val => {
        langMap.set(val.value, val.name);
    })
    return langMap;
}

export async function createResourceMap(): Promise<Resource> {
    let locales: Locale[] = Config.translations[getCustomerIdFromDomain()];
    if (!locales) {
        locales = Config.translations[DEFAULT_TEMPLATE_LABEL];
    }
    const resources: Resource = {};
    for (const locale of locales) {
        resources[locale.value] = await import(`./translations/CUPID-${locale.puff}.puff.json`) // must comply with the rules of vite dynamic imports: https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#limitations
    }
    return resources
}