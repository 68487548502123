import { Button, ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/esm/button';
import { InputFooterText } from '@amzn/stencil-react-components/esm/form';
import { Col, Row, Spacer, View } from '@amzn/stencil-react-components/esm/layout';
import { IconQuestionCircleFill } from '@amzn/stencil-react-components/esm/submodules/icons';
import { getBanner, getCustomerConfig } from '../../../globals/utils';
import { useTranslation } from 'react-i18next';
import AmazonLogo from '../../../assets/Default/Amazon_logo.png';
import { useFailoverStore } from '../../../context/failoverContext';
import { useLoginStore } from '../../../context/loginContext';
import FailoverBanner from '../FailoverUI/FailoverBanner';
import LanguageSelector from './LanguageSelector';
import SpinnerOverlay from './SpinnerOverlay';

interface CommonLoginViewProps {
    content: JSX.Element;
}

export default function CommonLoginView(props: CommonLoginViewProps) {
    const { t } = useTranslation();
    const { content } = props;
    const customerConfig = getCustomerConfig();
    const { userPoolId: failoverPoolId, isFailover } = useFailoverStore().failoverPoolConfig;
    const showSpinnerOverlay = useLoginStore((state) => state.showSpinnerOverlay);

    return (
        <Col
            minHeight='95vh'
            justifyContent='center'
            alignItems='stretch'
            gridGap='S100'
            padding='S100'
        >
            <Spacer flex={2} />
            {getBanner() && <Row justifyContent='center'>
                <img width='100%' src={getBanner().bannerSvg} alt={getBanner().altText} />
            </Row>}
            <View alignSelf='center' flex={5} minWidth='300px' maxWidth='400px'>
                {failoverPoolId != "" && isFailover &&
                    <FailoverBanner />
                }
                <SpinnerOverlay active={showSpinnerOverlay}>
                    {/* TODO: Commenting below until we have translations separated out from templates */}
                    {/* <Row gridGap='S400' alignItems='center'>
                    <img width='auto' height={60} src={getLogo()} alt='logo' title='Logo' />
                    <H2>{t('resources:title')}</H2>
                </Row> */}
                    <Spacer height={16} />
                    {content}
                    <Row
                        style={{ marginTop: 100 }}
                        gridGap='S200'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <LanguageSelector />
                        {(customerConfig.links && customerConfig.links.getHelp) && (<Button
                            icon={<IconQuestionCircleFill />}
                            variant={ButtonVariant.Tertiary}
                            size={ButtonSize.Small}
                            onClick={() => window.open(customerConfig.links!.getHelp, '_blank')}
                        >
                            {t('resources:getHelp')}
                        </Button>)}
                    </Row>
                    <Row
                        style={{ marginTop: 100 }}
                        gridGap='S200'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <InputFooterText>
                            {t('resources:commonFooter')}
                        </InputFooterText>
                    </Row>
                    <Row
                        style={{ marginTop: 10 }}
                        gridGap='S200'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <img width='auto' height={30} src={AmazonLogo} alt='Amazon Logo' />
                    </Row>
                </SpinnerOverlay>
            </View>
            <Spacer flex={2} />
        </Col>
    );
}
