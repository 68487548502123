import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';

import CommonLoginView from './CustomLoginUI/CommonLoginView';
import FirstPasswordStep from './CustomLoginUI/FirstPasswordStep';
import ForgotPasswordStep from './CustomLoginUI/ForgotPasswordStep';
import LoggedInStep from './CustomLoginUI/LoggedInStep';
import MFAStep from './CustomLoginUI/MFAStep';
import TooManyFailedAuthStep from './CustomLoginUI/TooManyFailedAuthStep';

import { LOGIN_FORM_STATES, useLoginStore } from '../../context/loginContext';
import { useOauthStore } from '../../context/oauthContext';
import { PROMPT_WEBAUTHN_LOCAL_STORAGE_KEY, TOKEN_STATUSES } from '../../globals/constants';
import * as CupidAuthService from '../../services/cupidAuthService';
import { doOauthRedirect, getUserEmail } from '../../globals/utils';
import CredentialManagement from './CredentialManagement/CredentialManagement';
import PasswordStep from './CustomLoginUI/PasswordStep';
import { TimeoutSpinnerStep } from './CustomLoginUI/TimeoutSpinnerStep';
import UsernameStep from './CustomLoginUI/UsernameStep';
import WebauthnStep from './CustomLoginUI/WebauthnStep';
import FailoverOTPWebAuthN from './FailoverUI/FailoverOTPWebAuthN';
import { isIdPrismHttpError } from '../../globals/errors';

// Map holding all view states
const LOGIN_FORM_VIEWS: Map<LOGIN_FORM_STATES, React.JSX.Element> = new Map([
  [LOGIN_FORM_STATES.USERNAME_STATE, <UsernameStep key={LOGIN_FORM_STATES.USERNAME_STATE} />],
  [LOGIN_FORM_STATES.PASSWORD_STATE, <PasswordStep key={LOGIN_FORM_STATES.PASSWORD_STATE} />],
  [LOGIN_FORM_STATES.FORGOT_PASSWORD_STATE, <ForgotPasswordStep key={LOGIN_FORM_STATES.FORGOT_PASSWORD_STATE} />],
  [LOGIN_FORM_STATES.NEW_PASSWORD_FIRST_LOGIN_STATE, <FirstPasswordStep key={LOGIN_FORM_STATES.NEW_PASSWORD_FIRST_LOGIN_STATE} />],
  [LOGIN_FORM_STATES.MFA_STATE, <MFAStep key={LOGIN_FORM_STATES.MFA_STATE} />],
  [LOGIN_FORM_STATES.LOGGED_IN_STATE, <LoggedInStep key={LOGIN_FORM_STATES.LOGGED_IN_STATE} />],
  [LOGIN_FORM_STATES.TOO_MANY_FAILED_AUTH, <TooManyFailedAuthStep key={LOGIN_FORM_STATES.TOO_MANY_FAILED_AUTH} />],
  [LOGIN_FORM_STATES.FAILOVER_EMAIL_OTP, <FailoverOTPWebAuthN key={LOGIN_FORM_STATES.FAILOVER_EMAIL_OTP} />],
  [LOGIN_FORM_STATES.FAILOVER_SMS_OTP, <FailoverOTPWebAuthN key={LOGIN_FORM_STATES.FAILOVER_SMS_OTP} />],
  [LOGIN_FORM_STATES.SPINNER, <TimeoutSpinnerStep key={LOGIN_FORM_STATES.SPINNER} />],
  [LOGIN_FORM_STATES.TOO_MANY_FAILED_AUTH, <TooManyFailedAuthStep key={LOGIN_FORM_STATES.TOO_MANY_FAILED_AUTH} />],
  [LOGIN_FORM_STATES.WEB_AUTHN_STATE, <WebauthnStep key={LOGIN_FORM_STATES.WEB_AUTHN_STATE} />],
  [LOGIN_FORM_STATES.CREDENTIAL_MANAGEMENT, <CredentialManagement key={LOGIN_FORM_STATES.WEB_AUTHN_STATE} />]

]);

export default function Login() {
  // Oauth Variables passed in URL
  const loginHint = useOauthStore((state) => state.loginHint);
  const redirectUri = useOauthStore((state) => state.redirectUri);
  const state = useOauthStore((state) => state.state);
  const responseType = useOauthStore((state) => state.responseType);
  const nonce = useOauthStore((state) => state.nonce);
  const scope = useOauthStore((state) => state.scope);
  const clientId = useOauthStore((state) => state.clientId);
  const relyingParty = useOauthStore((state) => state.relyingParty);

  const setErrorRedirecting = useLoginStore((state) => state.setErrorRedirection);
  const loginFormState = useLoginStore((state) => state.loginFormState);
  const cognitoUser = useLoginStore((state) => state.cognitoUser);
  const setLoginFormState = useLoginStore((state) => state.setLoginFormState);
  const setCognitoUser = useLoginStore((state) => state.setCognitoUser);
  const setUsername = useLoginStore((state) => state.setUsername);
  const setPassword = useLoginStore((state) => state.setPassword);
  const [viewContent, setViewContent] = useState(LOGIN_FORM_VIEWS.get(LOGIN_FORM_STATES.SPINNER)!);
  const setPromptRegisterWebauthn = useLoginStore((state) => state.setPromptRegisterWebauthn);
  const promptRegisterWebauthn = useLoginStore((state) => state.promptRegisterWebauthn);
  
  useEffect(() => {
    const getSession = async () => {
      if (cognitoUser) {
        let session: CognitoUserSession
        try {
          session = await Auth.currentSession();
        } catch (err) {
          // no current auth session so do not refresh token
          return;
        }
        // Attempt to refresh user session using Cognito Refresh Token 
        // then call IdPrism POST /authorize endpoint to generate authcode to be exchanged with Amazon Federate
        cognitoUser.refreshSession(session.getRefreshToken(), async (err: Error, newSession: CognitoUserSession) => {
          if (err) {
            // TODO update error handling (requestIds)
            console.error('Unable to refresh Token', err);
            return;
          }

          const idToken = newSession.getIdToken().getJwtToken();
          const expiresIn = newSession.getAccessToken().getExpiration().toString();

          if (relyingParty && clientId) {
            // Call to idprism auth endpoint
            try {
              const authResponse = await CupidAuthService.authorize({ idToken, keepUserSignedIn: true, relyingParty, clientId });
              if (expiresIn && state && redirectUri) {
                doOauthRedirect(
                  redirectUri,
                  authResponse.authCode, //idprism token
                  expiresIn,
                  state,
                  responseType,
                  nonce,
                  scope,
                  clientId
                );
                setLoginFormState(LOGIN_FORM_STATES.LOGGED_IN_STATE);
                setPassword('');
              }
            } catch(err) {
              setPassword('');
                // If session is expired then return to first login step
              if(isIdPrismHttpError(err)) {
                if(err.httpError.response?.data?.status === TOKEN_STATUSES.INVALID_SESSION) {
                  const userEmail = await getUserEmail(cognitoUser);
                  if (userEmail) setUsername(userEmail);
                  setLoginFormState(LOGIN_FORM_STATES.USERNAME_STATE);
                }
              }
              setErrorRedirecting(true)
              console.error('ERROR Logging In', err);
            }
            return;
          }
          setLoginFormState(LOGIN_FORM_STATES.LOGGED_IN_STATE);
        });
      }
    };
    getSession();
  }, [cognitoUser]);

  useEffect(() => {
    const checkCurrentSession = async () => {
      let user;
      try {
        user = await Auth.currentAuthenticatedUser();
      } catch (err) { /* empty */ }
      if (user) {
        setCognitoUser(user);
      } else {
        setLoginFormState(LOGIN_FORM_STATES.USERNAME_STATE);
      }
    };

    const localpromptRegisterWebauthn = localStorage.getItem(PROMPT_WEBAUTHN_LOCAL_STORAGE_KEY);
    if (localpromptRegisterWebauthn) {
      setPromptRegisterWebauthn(JSON.parse(localpromptRegisterWebauthn))
    }

    checkCurrentSession();
  }, []);

  useEffect(() => {
    if (loginHint) {
      setUsername(loginHint);
    }
  }, [loginHint]);

  // When the loginFormState is updated we set the content to be rendered here
  useEffect(() => {
    let content = LOGIN_FORM_VIEWS.get(loginFormState);
    if (!content) {
      content = <UsernameStep />;
    }

    setViewContent(content);
  }, [loginFormState])

  useEffect(() => {
    localStorage.setItem(PROMPT_WEBAUTHN_LOCAL_STORAGE_KEY, JSON.stringify(promptRegisterWebauthn))
  }, [promptRegisterWebauthn])

  return (
      <CommonLoginView content={viewContent} />
  );
}
