import {
  Button,
  ButtonVariant
} from '@amzn/stencil-react-components/esm/button';
import { Input, InputFooter } from '@amzn/stencil-react-components/esm/form';
import { Col, Row } from '@amzn/stencil-react-components/esm/layout';
import { Link } from '@amzn/stencil-react-components/esm/link';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/esm/message-banner';
import { Label } from '@amzn/stencil-react-components/esm/text';
import { Auth } from 'aws-amplify';
import i18n from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LOGIN_FORM_STATES, useLoginStore } from '../../../context/loginContext';
import { getCustomerConfig } from '../../../globals/utils';
import { IdPrismUser } from '../../../globals/types';

const COGNITO_SMS_MFA_CHALLENGE_LABEL = 'SMS_MFA';

export default function MFAStep() {
  const { t } = useTranslation();

  const intermediateUser = useLoginStore((state) => state.intermediateUser);
  const password = useLoginStore((state) => state.password)
  const username = useLoginStore((state) => state.username)
  const setIntermediateUser = useLoginStore((state) => state.setIntermediateUser);
  const setCognitoUser = useLoginStore((state) => state.setCognitoUser);
  const setLoginFormState = useLoginStore((state) => state.setLoginFormState);
  const promptRegisterWebauthn = useLoginStore((state) => state.promptRegisterWebauthn);

  const [mfaCode, setMfaCode] = useState('');
  const [showIncorrectMfaCodeError, setShowIncorrectMfaCodeError] = useState(
    false
  );
  const [showGeneralError, setShowGeneralError] = useState(false);
  const setShowSpinnerOverlay = useLoginStore((state) => state.setShowSpinnerOverlay);
  const [showMFAResendBanner, setShowMFAResendBanner] = useState(false);

  const customerConfig = getCustomerConfig();

  const onClickSubmitMFACode = async () => {
    setShowSpinnerOverlay(true);
    try {
      const user: IdPrismUser = await Auth.confirmSignIn(
        intermediateUser, // Return object from Auth.signIn()
        mfaCode, // Confirmation code
        COGNITO_SMS_MFA_CHALLENGE_LABEL // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
      );
      setShowGeneralError(false);
      setLoginFormState(LOGIN_FORM_STATES.SPINNER);
      if (promptRegisterWebauthn) {
        setLoginFormState(LOGIN_FORM_STATES.WEB_AUTHN_STATE);
      } else {
        setCognitoUser(user);
        setLoginFormState(LOGIN_FORM_STATES.SPINNER);
      }
    } catch (error) {
      setShowIncorrectMfaCodeError(true);
    } finally {
      setShowSpinnerOverlay(false)
    }
  };

  const resendMFACode = async () => {
    setShowSpinnerOverlay(false)
    setShowMFAResendBanner(true);
    setShowGeneralError(false);
    setShowIncorrectMfaCodeError(false);
    try {
      // workaround for MFA Code Resend https://github.com/aws-amplify/amplify-js/issues/6676 
      // https://issues.amazon.com/issues/COG-5195
      const user = await Auth.signIn(username, password);
      setIntermediateUser(user);
    } catch (error) {
      setShowGeneralError(true)
    }
  }

  const getMFAInputText = () => {
    if (!customerConfig.emailMFA) {
      return `${t('resources:enterSMSMfaCode')} ${(intermediateUser as unknown as { challengeParam: { CODE_DELIVERY_DESTINATION: string; CODE_DELIVERY_DELIVERY_MEDIUM: string } })?.challengeParam?.CODE_DELIVERY_DESTINATION} `
    }
    return `${t('resources:enterGenericMfaCode')}`;
  }

  return (
    <>
      {showMFAResendBanner &&
        <MessageBanner
          isDismissible
          onDismissed={() => setShowMFAResendBanner(false)}
          type={MessageBannerType.Success}
        >
          {i18n.t('resources:mfaResetBanner')}
        </MessageBanner >
      }
      {/* TODO: Commenting below until we have translations separated out from templates */}
      {/* <H3 id='login-step-header'>{t('resources:enterMfa')}</H3> */}
      <Col gridGap='S200'>
        <Col>
          <Label data-testid="mfaCodeLabel" htmlFor='input-id-4'>{getMFAInputText()}</Label>
          <Input
            data-testid='mfa-code-input'
            id='input-id-4'
            name='password'
            type='text'
            placeholder={`${t('resources:enterMfaPlaceholder')}`}
            value={mfaCode}
            onChange={(event) => { setMfaCode(event.target.value.trim()); setShowMFAResendBanner(false) }}
          />
          {showIncorrectMfaCodeError && (
            <InputFooter id='input-footer-5' error>
              {i18n.t('resources:incorrectVerificationCode')}
            </InputFooter>
          )}
          {showGeneralError && (
            <InputFooter id='input-footer-6' error>
              {i18n.t('resources:generalError')}
            </InputFooter>
          )}
        </Col>
        <Row alignItems='left'>
          <Link
            onClick={() => resendMFACode()}
            style={{ marginTop: 16, marginBottom: 16, textDecoration: 'none', cursor: 'pointer' }}
            fontSize={'small'}
          >
            {t('resources:resendCode')}
          </Link>
        </Row>
        <Button
          data-testid='mfa-code-submit'
          disabled={!mfaCode}
          onClick={onClickSubmitMFACode}
          style={{ width: '100%' }}
          variant={ButtonVariant.Primary}
        >
          {t('resources:verify')}
        </Button>
        <Button
          onClick={() =>
            setLoginFormState(LOGIN_FORM_STATES.USERNAME_STATE)
          }
          style={{ width: '100%' }}
        >
          {t('resources:back')}
        </Button>
      </Col >
    </>
  );
}
