import {
  Button,
  ButtonSize,
  ButtonVariant,
} from '@amzn/stencil-react-components/esm/button';
import { Input, InputFooter } from '@amzn/stencil-react-components/esm/form';
import { Col, Row } from '@amzn/stencil-react-components/esm/layout';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/esm/message-banner';
import { H3, Label, P } from '@amzn/stencil-react-components/esm/text';
import { Auth } from 'aws-amplify';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExclamationCircle from '../../../assets/exclamation-circle-small.svg';
import PasswordEyeHidden from '../../../assets/password-eye-hidden.svg';
import PasswordEyeUnhidden from '../../../assets/password-eye-unhidden.svg';
import { LOGIN_FORM_STATES, useLoginStore } from '../../../context/loginContext';
import { COGNITO_NEW_PASSWORD_REQUIRED_CHALLENGE_LABEL, COGNITO_SMS_MFA_CHALLENGE_LABEL, INCORRECT_USERNAME_OR_PASSWORD, LIMIT_EXCEEDED_EXCEPTION, PASSWORD_ATTEMPTS_EXCEEDED, PASSWORD_HIDDEN_ARIA, PASSWORD_UNHIDDEN_ARIA } from '../../../globals/constants';
import { WebAuthnError, WebAuthnErrors, WebAuthnSignIn } from './WebAuthnSignin';

export default function PasswordStep() {
  const { t } = useTranslation();
  const DEFAULT_ERRORS_STATE = {
    LOGIN_ERROR: { showError: false, errorMessage: `${t('resources:incorrectPassword')}` },
    WEBAUTHN_NOT_REGISTERED_ERROR: { showError: false, errorMessage: `${t('resources:noWebauthnRegistered')}` },
    WEBAUTHN_NOT_SUPPORT_ERROR: { showError: false, errorMessage: `${t('resources:webauthnNotSupported`')}` },
    GENERAL_ERROR: { showError: false, errorMessage: `${t('resources:generalError')}` },
  }
  // user state
  const setLoginFormState = useLoginStore((state) => state.setLoginFormState);
  const username = useLoginStore((state) => state.username);
  const setCognitoUser = useLoginStore((state) => state.setCognitoUser);
  const setIntermediateUser = useLoginStore((state) => state.setIntermediateUser);

  // password specific state
  const password = useLoginStore((state) => state.password);
  // need to store the password in state to resend MFA code https://github.com/aws-amplify/amplify-js/issues/6676
  const setPassword = useLoginStore((state) => state.setPassword);
  const promptRegisterWebauthn = useLoginStore((state) => state.promptRegisterWebauthn);

  // error state
  const errorRedirecting = useLoginStore((state) => state.errorRedirecting);
  const [showErrorRedirectingBanner, setShowErrorRedirectingBanner] = useState(false);
  const [errorMessages, setErrorMessages] = useState(DEFAULT_ERRORS_STATE);
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [passwordEye, setPasswordEye] = useState(PasswordEyeHidden);
  const [passwordEyeAriaLabel, setPasswordEyeAriaLabel] = useState(PASSWORD_HIDDEN_ARIA);
  const [showErrorCollectingWebAuthNKey, setShowErrorCollectingWebAuthNKey] = useState(false);

  const webauthnEnabled = useLoginStore((state) => state.webauthnEnabled);
  const setShowSpinnerOverlay = useLoginStore((state) => state.setShowSpinnerOverlay);
 
  useEffect(() => {
    if (errorRedirecting) {
      setShowErrorRedirectingBanner(true);
    }
  }, [errorRedirecting])


  useEffect(() => {
    if (passwordHidden) {
      setPasswordEyeAriaLabel(PASSWORD_HIDDEN_ARIA);
      setPasswordEye(PasswordEyeHidden);
    } else {
      setPasswordEyeAriaLabel(PASSWORD_UNHIDDEN_ARIA);
      setPasswordEye(PasswordEyeUnhidden);
    }
  }, [passwordHidden])

  // build all the error messages to be displayed 
  const getErrorMessages = () => {
    return Object.entries(errorMessages).map(([, value], i) => (
      <Fragment key={i}>
        {value.showError &&
          <InputFooter id={`error-input-footer-${i}`} error>
            {value.errorMessage}
          </InputFooter>
        }
      </Fragment>
    ));
  }

  const onSubmitPassword = async () => {
    setErrorMessages(DEFAULT_ERRORS_STATE);
    setShowSpinnerOverlay(true);
    try {
      const intermediateUser = await Auth.signIn(username, password);
      setIntermediateUser(intermediateUser);
      if (intermediateUser.challengeName === COGNITO_SMS_MFA_CHALLENGE_LABEL) {
        setLoginFormState(LOGIN_FORM_STATES.MFA_STATE);
      } else if (
        intermediateUser.challengeName ===
        COGNITO_NEW_PASSWORD_REQUIRED_CHALLENGE_LABEL
      ) {
        setLoginFormState(LOGIN_FORM_STATES.NEW_PASSWORD_FIRST_LOGIN_STATE);
      } else if (promptRegisterWebauthn) {
        setLoginFormState(LOGIN_FORM_STATES.WEB_AUTHN_STATE);
      } else {
        setCognitoUser(intermediateUser);
        setLoginFormState(LOGIN_FORM_STATES.SPINNER);
      }
    } catch (error) {
      const authError = error as Error;
      if (authError.message === PASSWORD_ATTEMPTS_EXCEEDED) {
        setLoginFormState(LOGIN_FORM_STATES.TOO_MANY_FAILED_AUTH);
      } else if (authError.message.includes(INCORRECT_USERNAME_OR_PASSWORD)) {
        // setErrorMessageText(`${t('resources:loginError')}`);
        setErrorMessages((prevState) => ({
          ...prevState,
          LOGIN_ERROR: { ...prevState.LOGIN_ERROR, showError: true }
        }));
      } else {
        setErrorMessages((prevState) => ({
          ...prevState,
          GENERAL_ERROR: { ...prevState.GENERAL_ERROR, showError: true }
        }));
      }
    } finally {
      setShowSpinnerOverlay(false);
    }
  };

  const onClickForgotPassword = async () => {
    setErrorMessages(DEFAULT_ERRORS_STATE);
    try {
      await Auth.forgotPassword(username);
      setShowSpinnerOverlay(false);
      setLoginFormState(LOGIN_FORM_STATES.FORGOT_PASSWORD_STATE);
    } catch (error) {
      const authError = error as Error;
      if (authError.name === LIMIT_EXCEEDED_EXCEPTION) {
        setLoginFormState(LOGIN_FORM_STATES.TOO_MANY_FAILED_AUTH);
      } else {
        setErrorMessages((prevState) => ({
          ...prevState,
          GENERAL_ERROR: { ...prevState.GENERAL_ERROR, showError: true }
        }));
      }
    }
  };

  function onWebAuthnError(error: WebAuthnError) {
    switch(error) {
      case WebAuthnErrors.BIOMETRICS_NOT_SUPPORTED:
        setShowErrorCollectingWebAuthNKey(true);
        break;
      case WebAuthnErrors.INCORRECT_WEBAUTHN:
        setErrorMessages((prevState) => ({
          ...prevState,
          LOGIN_ERROR: { ...prevState.LOGIN_ERROR, showError: true },
          WEBAUTHN_NOT_SUPPORT_ERROR: { ...prevState.WEBAUTHN_NOT_SUPPORT_ERROR, showError: true }
        }));
      break;
      case WebAuthnErrors.NO_CREDENTIALS_REGISTERED:
        setErrorMessages((prevState) => ({
          ...prevState,
          WEBAUTHN_NOT_REGISTERED_ERROR: { ...prevState.WEBAUTHN_NOT_REGISTERED_ERROR, showError: true }
        }));
        break;
      default:
        setErrorMessages((prevState) => ({
          ...prevState,
          GENERAL_ERROR: { ...prevState.GENERAL_ERROR, showError: true }
        }));
    }
  }

  // TODO Update for WebAuthN mandatory for userpool, no step-up authentication (hide password button)
  return (
    <>
      {showErrorRedirectingBanner &&
        <MessageBanner
          isDismissible
          onDismissed={() => setShowErrorRedirectingBanner(false)}
          type={MessageBannerType.Error}
        >
          {t('resources:errorRedirecting')}
        </MessageBanner >
      }
      <H3 id='login-step-header'> {t('resources:passwordTitle')}</H3>
      <Col gridGap='S200'>
        <Col gridGap='S200'>
          <Label
            htmlFor='input-id-2'
            color={errorMessages.LOGIN_ERROR.showError ? 'red70' : 'default'}
          >
            {t('resources:password')}
          </Label>
          <Input
            data-testid="password-input"
            id='input-id-2'
            name='password'
            type={passwordHidden ? 'password' : 'text'}
            autoComplete='current-password'
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            error={errorMessages.LOGIN_ERROR.showError}
            insetElementTrailing={{
              element:
                <img
                  width='auto'
                  aria-label={t(passwordEyeAriaLabel) as string}
                  src={passwordEye}
                  alt='ShowPasswordButton'
                  onClick={() => setPasswordHidden(!passwordHidden)}
                />
            }}
          />
          {getErrorMessages()}
          {showErrorCollectingWebAuthNKey && (
            <Row
              gridGap='S200'
              justifyContent='center'
              alignItems='center' hidden={showErrorCollectingWebAuthNKey}>
              <img width='auto' src={ExclamationCircle} alt='ExclamationCircle' />
              <P fontSize="T200">{t('resources:errorCapturingBiometrics')}</P>
            </Row>)}
        </Col >
        {webauthnEnabled &&
          <Row gridGap='S200' alignItems='right' justifyContent='right'>
            <WebAuthnSignIn
              onClick={() => {setErrorMessages(DEFAULT_ERRORS_STATE)}}
              onError={onWebAuthnError}
              setLoading={setShowSpinnerOverlay}
              />
          </Row>
        }
        <Button
          data-testid="continue-password-button"
          disabled={!password}
          onClick={onSubmitPassword}
          style={{ width: '100%', marginTop: '24px' }}
          variant={ButtonVariant.Primary}
        >
          {t('resources:continue')}
        </Button>
        <Button
          onClick={() => {
            setLoginFormState(LOGIN_FORM_STATES.USERNAME_STATE)
          }
          }
          style={{ width: '100%' }}
        >
          {t('resources:back')}
        </Button>
        <Button
          size={ButtonSize.Small}
          onClick={onClickForgotPassword}
          variant={ButtonVariant.Tertiary}
        >
          {t('resources:forgotPassword')}
        </Button>
      </Col>
    </>
  );
}
