import { Button, ButtonVariant } from '@amzn/stencil-react-components/esm/button';
import { Input, InputFooter } from '@amzn/stencil-react-components/esm/form';
import { Col } from '@amzn/stencil-react-components/esm/layout';
import { Modal, ModalContent } from '@amzn/stencil-react-components/esm/modal';
import { Label, Text } from '@amzn/stencil-react-components/esm/text';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import i18n from 'i18next';

export interface NameCredentialModalProps {
    setShowModal: (show: boolean) => void;
    showModal: boolean
    setCredentialName: (name: string) => void;
    credentialName: string
}

export default function NameCredentialModal({ setShowModal, showModal, setCredentialName, credentialName }: NameCredentialModalProps) {
    const { t } = useTranslation();
    const [isValid, setValid] = useState(true);

    const handleNameCredential = () => {
        const isAlphanumeric = /^[a-z0-9]+$/i.test(credentialName);
        if (!isAlphanumeric) {
            setValid(false);
            return;
        }
        setValid(true);
        setShowModal(false);
    }

    return (
        <Modal isOpen={showModal} close={() => setShowModal(false)} >
            <ModalContent
                titleText={t('resources:nameCredentialHeader')}
                buttons={[
                    <Button
                        data-testid='submit-credential-name-button'
                        disabled={!credentialName.length}
                        variant={ButtonVariant.Secondary}
                        onClick={() => handleNameCredential()}
                        key='submit-credential-name-button'
                    >
                        {t('resources:continue')}
                    </Button>
                ]}>
                <Col width="100%" gridGap="S200">
                    <Text data-testid='name-credential-message' style={{ whiteSpace: 'normal' }}>
                        {t('resources:nameCredentialMessage')}
                    </Text>
                    <Col gridGap="S200">
                        <Label data-testid='name-credential-label'>
                            {t('resources:credentialName')}
                        </Label>
                        <Input
                            data-testid='name-credential-input'
                            id="credential-name-input"
                            type="text"
                            maxLength={24}
                            onChange={(e) => setCredentialName(e.target.value)}
                            value={credentialName}
                        />
                        {!isValid && (
                          <InputFooter id='input-footer-5' error>
                              {i18n.t('resources:errorValidatingCredentialName')}
                          </InputFooter>
                        )}
                    </Col>
                </Col>
            </ModalContent>
        </Modal>
    );
}
