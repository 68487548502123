import {
  Button
} from '@amzn/stencil-react-components/esm/button';
import { Row, Spacer, View } from '@amzn/stencil-react-components/esm/layout';
import { H2, Text } from '@amzn/stencil-react-components/esm/text';
import exclamationCircle from '../../../assets/exclamation-circle.svg';
import { LOGIN_FORM_STATES, useLoginStore } from '../../../context/loginContext';
import { useTranslation } from 'react-i18next';


export default function TooManyFailedAuthStep() {
  const { t } = useTranslation();

  const setLoginFormState = useLoginStore((state) => state.setLoginFormState);

  return (
    <>
      <Row height={50} justifyContent='center'><img width='auto' alt='Error Icon' height={60} src={exclamationCircle} /></Row>
      <H2 margin={20} textAlign='center'>{t('resources:passwordAttemptsExceeded')}</H2>
      <Text fontSize='small' textAlign='center'>{t('resources:tryAgainAfterWaiting')}</Text>
      <Spacer height='20px' />
      <Row justifyContent='center'>
        <View maxWidth='400px'>
          <Button
            onClick={() =>
              setLoginFormState(LOGIN_FORM_STATES.USERNAME_STATE)
            }
            style={{ width: '100%' }}
          >
            {t('resources:returnToLogin')}
          </Button>
        </View>
      </Row>
    </>
  );
}
