import { Resource } from 'i18next';
import * as arAE from './translations/IdPrismA2ZAlumniLogin-ar-AE.puff.json';
import * as csCZ from './translations/IdPrismA2ZAlumniLogin-cs-CZ.puff.json';
import * as deDE from './translations/IdPrismA2ZAlumniLogin-de-DE.puff.json';
import * as enCA from './translations/IdPrismA2ZAlumniLogin-en-CA.puff.json';
import * as enGB from './translations/IdPrismA2ZAlumniLogin-en-GB.puff.json';
import * as enIN from './translations/IdPrismA2ZAlumniLogin-en-IN.puff.json';
import * as enUS from './translations/IdPrismA2ZAlumniLogin-en-US.puff.json';
import * as esES from './translations/IdPrismA2ZAlumniLogin-es-ES.puff.json';
import * as frCA from './translations/IdPrismA2ZAlumniLogin-fr-CA.puff.json';
import * as frFR from './translations/IdPrismA2ZAlumniLogin-fr-FR.puff.json';
import * as idID from './translations/IdPrismA2ZAlumniLogin-id-ID.puff.json';
import * as itIT from './translations/IdPrismA2ZAlumniLogin-it-IT.puff.json';
import * as jaJP from './translations/IdPrismA2ZAlumniLogin-ja-JP.puff.json';
import * as neNP from './translations/IdPrismA2ZAlumniLogin-ne-NP.puff.json';
import * as nlNL from './translations/IdPrismA2ZAlumniLogin-nl-NL.puff.json';
import * as plPL from './translations/IdPrismA2ZAlumniLogin-pl-PL.puff.json';
import * as ptBR from './translations/IdPrismA2ZAlumniLogin-pt-BR.puff.json';
import * as roRO from './translations/IdPrismA2ZAlumniLogin-ro-RO.puff.json';
import * as ruRU from './translations/IdPrismA2ZAlumniLogin-ru-RU.puff.json';
import * as skSK from './translations/IdPrismA2ZAlumniLogin-sk-SK.puff.json';
import * as soSO from './translations/IdPrismA2ZAlumniLogin-so-SO.puff.json';
import * as trTR from './translations/IdPrismA2ZAlumniLogin-tr-TR.puff.json';
import * as ukUA from './translations/IdPrismA2ZAlumniLogin-uk-UA.puff.json';
import * as viVN from './translations/IdPrismA2ZAlumniLogin-vi-VN.puff.json';
import * as zhCN from './translations/IdPrismA2ZAlumniLogin-zh-CN.puff.json';
import * as zhTW from './translations/IdPrismA2ZAlumniLogin-zh-TW.puff.json';

const locales = [
    {
        name: "العربية",
        value: "ar-AE",
        puff: arAE
    },
    {
        name: "Čeština",
        value: "cs-CZ",
        puff: csCZ
    },
    {
        name: "Deutsch",
        value: "de-DE",
        puff: deDE
    },
    {
        name: "English (US)",
        value: "en-US",
        puff: enUS
    },
    {
        name: "English (UK)",
        value: "en-GB",
        puff: enGB
    },
    {
        name: "English (CA)",
        value: "en-CA",
        puff: enCA
    },
    {
        name: "English (IN)",
        value: "en-IN",
        puff: enIN
    },
    {
        name: "Español",
        value: "es-ES",
        puff: esES
    },
    {
        name: "Français (Canada)",
        value: "fr-CA",
        puff: frCA
    },
    {
        name: "Français",
        value: "fr-FR",
        puff: frFR
    },{
        name: "Bahasa Indonesia",
        value: "id-ID",
        puff: idID
    },
    {
        name: "Italiano",
        value: "it-IT",
        puff: itIT
    },
    {
        name: "日本語",
        value: "ja-JP",
        puff: jaJP
    },
    {
        name: 'नेपाल',
        value: 'ne-NP',
        puff: neNP
    },
    {
        name: "Nederlands",
        value: "nl-NL",
        puff: nlNL
    },
    {
        name: "Polski",
        value: "pl-PL",
        puff: plPL
    },
    {
        name: "Português (Brasil)",
        value: "pt-BR",
        puff: ptBR
    },
    {
        name: "Română",
        value: "ro-RO",
        puff: roRO
    },
    {
        name: "русский",
        value: "ru-RU",
        puff: ruRU
    },
    {
        name: "slovenský jazyk",
        value: "sk-SK",
        puff: skSK
    },
    {
        name: "Soomaali",
        value: "so-SO",
        puff: soSO
    },
    {
        name: "Türk",
        value: "tr-TR",
        puff: trTR
    },
    {
        name: "Ukrayna",
        value: "uk-UA",
        puff: ukUA
    },
    {
        name: "Tiếng Việt",
        value: "vi-VN",
        puff: viVN
    },
    {
        name: "简体中文",
        value: "zh-CN",
        puff: zhCN
    },
    {
        name: "繁體中文",
        value: "zh-TW",
        puff: zhTW
    }
  ];
  
  export function createLangMap() {
    const langMap = new Map();
    locales.map(val => {
        langMap.set(val.value, val.name);
    })
    return langMap;
  }

   export function createResourceMap(): Resource {
    const resources: Resource = {};
    locales.map(val => {
        resources[val.value] = val.puff;
    });
    return resources
   }