import { create } from "zustand";
import { IdPrismHttpError } from "../globals/errors";

interface AxiosErrorContext {
    errors: IdPrismHttpError[];
    addError: (error: IdPrismHttpError) => void;
    removeError: (error: IdPrismHttpError) => void;
}

export const useAxiosErrorStore = create<AxiosErrorContext>((set) => {
    return {
        errors: [],
        addError: (error) => {
            set((state) => ({ errors: [...state.errors, error] }))
            setTimeout(() => {
                set((state) => ({ errors: state.errors.filter((e) => e !== error) }))
            // Remove the error from the screen after 10 seconds
            }, 10_000)
        },
        removeError: (error) => {
            set((state) => ({ errors: state.errors.filter((e) => e !== error) }))
        }    
    }
})