import AtoZLoginView from './AtoZ/Login';
import DefaultLoginView from './Default/Login';
import AtoZLogoutView from './AtoZ/Logout';
import DefaultLogoutView from './Default/Logout';
import { getCustomerConfig } from '../globals/utils';
import { CustomerConfig, TemplateLabel } from '../globals/types';
import React from 'react';

const loginMap: { [key in TemplateLabel]: React.JSX.Element } = {
    "atoz": <AtoZLoginView key="atoz" />,
    "default": <DefaultLoginView key="default" />
};
const logoutMap: { [key in TemplateLabel]: React.JSX.Element } = {
    "atoz": <AtoZLogoutView key="atoz" />,
    "default": <DefaultLogoutView key="default" />
};
export function LoginView(): React.JSX.Element {
    const customerConfig: CustomerConfig = getCustomerConfig();
    const template = customerConfig.template || "default";
    return loginMap[`${template}`] || loginMap["default"];
}

export function LogoutView(): React.JSX.Element {
    const customerConfig: CustomerConfig = getCustomerConfig();
    const template = customerConfig.template || "default";
    return logoutMap[`${template}`] || logoutMap["default"];
}