import { create } from 'zustand';

interface FailoverPoolDetails {
    userPoolId: string;
    appClientId: string;
    region:string;

    // True for Failover, false for Active-Active
    isFailover?: boolean;
} 

export const DEFAULT_FAILOVER_STATE: FailoverPoolDetails = {
    userPoolId: "",
    appClientId: "",
    region: ""
}

interface FailoverState {
  failoverPoolConfig: FailoverPoolDetails;

  setFailoverPoolConfig: (failoverPoolConfig: FailoverPoolDetails) => void;
}

export const useFailoverStore = create<FailoverState>()((set) => ({
    failoverPoolConfig: DEFAULT_FAILOVER_STATE,

    setFailoverPoolConfig: (failoverPoolConfig: FailoverPoolDetails) => {
        set(() => ({ failoverPoolConfig }));
    },
}));
