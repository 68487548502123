import { i18n } from 'i18next';
import { object, string } from 'yup';
import AtoZLogo from '../assets/AtoZ/alumni_logo.png';
import IdPrismLogo from '../assets/Default/icon.svg';
import Config from '../customer-config';
import PhloxBanner from '../assets/Default/banner_phlox.svg';
import {  FAILED_TO_GET_WEBAUTHN_CHALLENGE_ANSWER, QUERY_PARAM_LABELS, STAGE, DYNAMIC_CLIENT_CONFIG } from './constants';
import { ChallengeUser, CredentialData, CustomerBanner, CustomerConfig, PreferredAuthMethod, StageConfig, TemplateLabel, WEBAUTHN_SUPPORTED_VALUES } from './types';
import * as CupidAuthService from '../services/cupidAuthService';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

export const doOauthRedirect = (
  redirectUri: string,
  authCode: string,
  expiresIn: string,
  state: string,
  responseType: string,
  nonce: string,
  scope: string,
  clientId: string
) => {
  if (!redirectUri || !authCode || !expiresIn || !state || !responseType || !scope || !clientId) {
    console.log('INVALID REDIRECT PARAMS');
    return;
  }

  const url = new URL(redirectUri);

  url.searchParams.append(QUERY_PARAM_LABELS.CODE, authCode);
  url.searchParams.append(QUERY_PARAM_LABELS.EXPIRES_IN, expiresIn);
  url.searchParams.append(QUERY_PARAM_LABELS.STATE, state);
  url.searchParams.append(QUERY_PARAM_LABELS.NONCE, nonce);
  url.searchParams.append(QUERY_PARAM_LABELS.RESPONSE_TYPE, responseType);
  url.searchParams.append(QUERY_PARAM_LABELS.SCOPE, scope);
  url.searchParams.append(QUERY_PARAM_LABELS.CLIENT_ID, clientId);
  window.location.replace(url);
};

export const getCustomerIdFromDomain = (): string => {
  let customerPrefix = window.location.hostname.split('.cupid')[0];
  // Optionally import customer ID directly from env if on local development environment
  if (import.meta.env.VITE_STAGE === 'dev') {
    customerPrefix = import.meta.env.VITE_CUSTOMER;
  }
  return customerPrefix
}

const logoMap: {[key in TemplateLabel]: string} = {
  "atoz": AtoZLogo,
  "default": IdPrismLogo
}

export const getLogo = () => {
  const template = getCustomerConfig().template || "default";
  return logoMap[template] || logoMap["default"];
}

const bannerMap: {[key: string]: CustomerBanner} = {
  'globalmilephloxapp': {
    bannerSvg: PhloxBanner,
    altText: "Phlox Informational Banner"
  }
}

export const getBanner = () => {
  const customer = getCustomerIdFromDomain();
  return bannerMap[customer];
}

export const constructPasswordSchema = (i18n: i18n) => {
  return object().shape({
    password: string()
      .min(8, `${i18n.t('resources:passwordMinLength')}`)
      .max(64, `${i18n.t('resources:passwordMaxLength')}`)
      .required(`${i18n.t('resources:passwordRequired')}`)
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])/,
        `${i18n.t('resources:passwordCharacterRestrictions')}`
      )
  });
}

export const getCustomerConfig = (): CustomerConfig => {
  // Retrieving the stage and customer specific userpool details
  const stageConfig: StageConfig = Config.stage;
  const customers = stageConfig[STAGE];
  const customerId = getCustomerIdFromDomain()
  let customerConfig: CustomerConfig = customers[customerId];
  if(!customerConfig && DYNAMIC_CLIENT_CONFIG) {
    customerConfig = JSON.parse(DYNAMIC_CLIENT_CONFIG)
  }
  return customerConfig;
}

export const getRegionFromUserpoolId = (userPoolId: string): string => {
  return userPoolId.split('_')[0];
}

// Base64URL to ArrayBuffer
export function webAuthnBufferDecode(value: string) {
  value = value.replace(/-/g, '+').replace(/_/g, '/');
  while (value.length % 4) {
    value += '=';
  }
  return Uint8Array.from(atob(value), (c) => c.charCodeAt(0));
}

// ArrayBuffer to URLBase64
export function webAuthnBufferEncode(value: ArrayBuffer) {
  return btoa(String.fromCharCode(...new Uint8Array(value)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

// Registers a webauthn Credential for a given user
export async function registerCredential(userName: string, jwtToken: string, name?: string, authenticatorAttachment?: AuthenticatorAttachment) {
  const hostName = window.location.host;
  const hostNameWithoutPort = hostName.split(":")[0];

  const {session} = await CupidAuthService.beginRegister({relyingPartyId: hostNameWithoutPort, token: jwtToken});

  // TODO build create request from backend Registration Options
  let newCredentialInfo: PublicKeyCredential | null = null;
  try {
    newCredentialInfo = await navigator.credentials.create({
      publicKey: {
        challenge: webAuthnBufferDecode(session.challenge),
        rp: { name: hostName, id: hostNameWithoutPort },
        user: {
          id: webAuthnBufferDecode(session.user_id),
          name: userName,
          displayName: userName
        },
        pubKeyCredParams: [{ type: 'public-key', alg: -7 }, { type: 'public-key', alg: -257 }],
        attestation: 'direct',
        authenticatorSelection: {
          userVerification: 'required',
          authenticatorAttachment
        }
      }
    }) as PublicKeyCredential | null
  } catch (err) {
    throw new Error(`Error with navigator credential create: ${err}`);
  }
  let credentialData: CredentialData;
  if (newCredentialInfo && newCredentialInfo.response instanceof AuthenticatorAttestationResponse) {
    credentialData = {
      id: newCredentialInfo.id,
      type: newCredentialInfo.type,
      rawId: webAuthnBufferEncode(newCredentialInfo.rawId),
      response: {
        clientDataJSON: webAuthnBufferEncode(
          newCredentialInfo.response.clientDataJSON,
        ),
        attestationObject: webAuthnBufferEncode(
          newCredentialInfo.response.attestationObject
        ),
      },
    };
  } else {
    throw new Error('Authenticator response invalid');
  }

  await CupidAuthService.completeRegister({
    token: jwtToken,
    challenge: session.challenge,
    relyingPartyId: hostNameWithoutPort,
    credentialData,
    name
  });
}


/** 
*  Gets a webauthn credential challenge answer for the provided user.
*
*  Generate the user input by calling Auth.signIn(username)
*/
export async function GetWebauthnChallengeAnswer(userToAuthenticate: ChallengeUser, transports?: AuthenticatorTransport[]) {
  try {
    const allowedCredentials: Credential[] = JSON.parse(userToAuthenticate.challengeParam.allowCredentials);
    const credentialRequestOptions: PublicKeyCredentialRequestOptions = {
      challenge: webAuthnBufferDecode(userToAuthenticate.challengeParam.challenge),
      userVerification: 'required',
      allowCredentials: allowedCredentials.map(credential => {
        const credentialDescriptor: PublicKeyCredentialDescriptor = {
          id: webAuthnBufferDecode(credential.id),
          type: 'public-key',
          transports
        }
        return credentialDescriptor;
      }),
    }
    const assertion = await navigator.credentials.get({
      publicKey: credentialRequestOptions,
    }) as PublicKeyCredential | null;

    if (assertion && assertion.response instanceof AuthenticatorAssertionResponse) {
      const authData = assertion.response.authenticatorData;
      const clientDataJSON = assertion.response.clientDataJSON;
      const rawId = assertion.rawId;
      const sig = assertion.response.signature;
      const userHandle = assertion.response.userHandle;
      return JSON.stringify({
        id: assertion.id,
        rawId: webAuthnBufferEncode(rawId),
        type: assertion.type,
        response: {
          authenticatorData: webAuthnBufferEncode(authData),
          clientDataJSON: webAuthnBufferEncode(clientDataJSON),
          signature: webAuthnBufferEncode(sig),
          userHandle: webAuthnBufferEncode(userHandle ? userHandle : new Uint8Array()),
        },
      });
    } else {
      throw new Error("Webauthn assertion response invalid")
    }
  } catch (err) {
    const credentialError: Error = err as Error;
    const error = new Error("Failed to capture Webauthn Credential: " + credentialError);
    error.name = credentialError.name !== 'Error' ? credentialError.name : FAILED_TO_GET_WEBAUTHN_CHALLENGE_ANSWER;
    throw error;
  }
}

export const capitalizeFirstLetter = (resource: string) => {
  return resource.charAt(0).toLocaleUpperCase() + resource.slice(1);
}

export function getPreferredAuthMethod(customerConfig:CustomerConfig): PreferredAuthMethod {
  switch(customerConfig.webauthnSupport) {
      case WEBAUTHN_SUPPORTED_VALUES.MANDATORY:
      case WEBAUTHN_SUPPORTED_VALUES.OPTIONAL:
        return 'WebAuthN';
      default:
        return 'OTP';
    }
}

export async function getUserEmail(user: CognitoUser) {
  const attributes = await Auth.userAttributes(user);
  for (const attribute of attributes) {
      if (attribute.Name === 'email') {
          return attribute.Value;
      }
  }
}