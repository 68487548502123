import { Button, ButtonSize } from '@amzn/stencil-react-components/esm/button';
import { Col } from '@amzn/stencil-react-components/esm/layout';
import { IconBin } from '@amzn/stencil-react-components/esm/submodules/icons/icons';
import { RowData, Table, TableColumn } from '@amzn/stencil-react-components/esm/table';
import { H2 } from '@amzn/stencil-react-components/esm/text';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCredentialManagementStore } from '../../../context/credentialManagementContext';
import { WebAuthNCredential } from '../../../globals/types';
import DeleteCredentialModal from './DeleteCredentialModal';
import { LOGIN_FORM_STATES, useLoginStore } from '../../../context/loginContext';

export default function CredentialManagement() {
    const { t } = useTranslation();

    const getListCredentials = useCredentialManagementStore((state) => state.getListCredentials);
    const credentials = useCredentialManagementStore((state) => state.data);
    const deleteSuccess = useCredentialManagementStore((state) => state.deleteSuccess);
    const deleteError = useCredentialManagementStore((state) => state.deleteError);
    const isGettingCredentials = useCredentialManagementStore(state => state.loading);
    const setLoginFormState = useLoginStore((state) => state.setLoginFormState);
    const setShowSpinnerOverlay = useLoginStore((state) => state.setShowSpinnerOverlay);
    const [credentialToDelete, setCredentialToDelete] = useState<WebAuthNCredential | null>(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        getListCredentials()
    }, [deleteSuccess, deleteError]);

    useEffect(() => {
        setShowSpinnerOverlay(isGettingCredentials);
    }, [isGettingCredentials]);

    const columns: TableColumn[] = [
        { header: 'Registration Time', accessor: ({ data }: RowData) => (data as WebAuthNCredential).registrationDate },
        { header: 'Name', accessor: ({ data }: RowData) => { return (data as WebAuthNCredential).name ?? data.id.substring(0, 5)} },
        {
            cellComponent: ({ data }: RowData) => {
                return <Button
                    data-testid='delete-credential-button'
                    icon={<IconBin aria-hidden={true} />}
                    isDestructive={true}
                    onClick={() => { setCredentialToDelete(data); setShowDeleteModal(true) }}
                    size={ButtonSize.Small}
                />
            },
            header: '',
        },
    ];

    return (
        <>
            <Col gridGap='S200'>
                <H2 id="table-label" data-testid='table-header' fontSize="T400">
                    {t('resources:credentialsHeader')}
                </H2>
                <Table data-testid='credential-table' aria-labelledby="table-label" columns={columns} data={credentials ?? []} />
                <DeleteCredentialModal credential={credentialToDelete} showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} />
                <Button
                    onClick={() =>
                        setLoginFormState(LOGIN_FORM_STATES.LOGGED_IN_STATE)
                    }
                    data-testid="back-button"
                    style={{ width: '100%' }}
                >
                    {t('resources:back')}
                </Button>
            </Col>
        </>
    );
}
