import { Button, ButtonVariant } from '@amzn/stencil-react-components/esm/button';
import { Col, Row } from '@amzn/stencil-react-components/esm/layout';
import { H2, P } from '@amzn/stencil-react-components/esm/text';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExclamationCircle from '../../../assets/exclamation-circle-small.svg';
import { useCredentialManagementStore } from '../../../context/credentialManagementContext';
import { LOGIN_FORM_STATES, useLoginStore } from '../../../context/loginContext';
import { WebAuthNCredential } from '../../../globals/types';
import { capitalizeFirstLetter, getUserEmail, registerCredential } from '../../../globals/utils';
import { useWebauthnIcons, useWebauthnStrings, WebauthnIconSize } from '../../../hooks/webauthnHooks';

export default function WebauthnStep() {
    const { t } = useTranslation();
    const intermediateUser = useLoginStore((state) => state.intermediateUser);
    const setCognitoUser = useLoginStore((state) => state.setCognitoUser);
    const setLoginFormState = useLoginStore((state) => state.setLoginFormState);
    const loginFormState = useLoginStore((state) => state.loginFormState);
    const [showErrorCollectingBiometricKey, setShowErrorCollectingBiometricKey] = useState(false);
    const setPromptRegisterWebauthn = useLoginStore((state) => state.setPromptRegisterWebauthn);
    const setShowSpinnerOverlay = useLoginStore((state) => state.setShowSpinnerOverlay);
    const [showBiometricsNotSupportedError, setShowBiometricsNotSupportedError] = useState(false);
    const getListCredentials = useCredentialManagementStore((state) => state.getListCredentials);
    const deleteCredentials = useCredentialManagementStore((state) => state.deleteCredential);
    const { data, deleteError, error, success, deleteSuccess } = useCredentialManagementStore(state => state)
    const [webauthnKeys, setWebauthnKeys] = useState([] as WebAuthNCredential[]);
    const [errorDisablingCredentials, setErrorDisablingCredentials] = useState(false);
    const [deleteClicked, setDeleteClicked] = useState(false);
    const cognitoUser = useLoginStore((state) => state.cognitoUser);
    const webauthnIcons = useWebauthnIcons(WebauthnIconSize.LARGE);
    const setWebauthnRegisteredOnDevice = useLoginStore((state) => state.setWebauthnRegisteredOnDevice);
    const webauthnStrings = useWebauthnStrings();
    const setUsername = useLoginStore((state) => state.setUsername);
    const username = useLoginStore((state) => state.username)

    useEffect(() => {
        setErrorDisablingCredentials(false);
        getListCredentials();
    }, []);

    const onClickEnable = async () => {
        if (!window.PublicKeyCredential) { // Client not capable of webauthn
            setShowBiometricsNotSupportedError(true);
            return;
        }
        // call register apis
        const user = intermediateUser ? intermediateUser : cognitoUser
        if (user) {
            try {
                setShowSpinnerOverlay(true);
                const session = await Auth.currentSession();
                let credUsername = username;
                if (!credUsername) {
                    credUsername = await getUserEmail(user) ?? user.getUsername();
                    setUsername(credUsername)
                }
                await registerCredential(credUsername, session.getIdToken().getJwtToken(), undefined, 'platform');
                setWebauthnRegisteredOnDevice(true);
                if (loginFormState !== LOGIN_FORM_STATES.BIOMETRICS_STATE) {
                    setLoginFormState(LOGIN_FORM_STATES.ATOZ_WEBAUTHN_ENABLED_STATE);
                } else {
                    setPromptRegisterWebauthn(false);
                    setCognitoUser(user);
                    setLoginFormState(LOGIN_FORM_STATES.SPINNER);
                }
            } catch (err) {
                setShowErrorCollectingBiometricKey(true);
            } finally {
                setShowSpinnerOverlay(false);
            }
        }
    }

    const onClickNotNow = () => {
        if (intermediateUser) setCognitoUser(intermediateUser);
        setPromptRegisterWebauthn(false);
        setLoginFormState(LOGIN_FORM_STATES.SPINNER);
    }

    // Initiate credential wipe
    const onClickTurnOff = () => {
        setDeleteClicked(true);
    }

    // Detecting any errors when retrieving/deleting credentials
    useEffect(() => {
        if (deleteClicked && (error || deleteError)) {
            setErrorDisablingCredentials(true);
        }
    }, [error, deleteError, deleteClicked])

    // Setting local state variable to preloaded list of available webauthn keys
    useEffect(() => {
        if (success) {
            setWebauthnKeys([
                ...data
            ])
            if (data.length > 0 && loginFormState !== LOGIN_FORM_STATES.BIOMETRICS_STATE) {
                setLoginFormState(LOGIN_FORM_STATES.ATOZ_DISABLE_WEBAUTHN_STATE);
            }
        }
    }, [success])

    // Deleting any existing webauthn keys. When there are none left, redirects to finished state
    useEffect(() => {
        if (deleteClicked) {
            if (webauthnKeys.length > 0) {
                const deleteKey = webauthnKeys[webauthnKeys.length - 1];
                deleteCredentials(deleteKey.id);
            } else {
                setWebauthnRegisteredOnDevice(false);
                setLoginFormState(LOGIN_FORM_STATES.ATOZ_WEBAUTHN_DISABLED_STATE);
            }
        }
    }, [webauthnKeys, deleteClicked])

    // When a delete operation has completed this removes the key from local state, triggering another check for keys to delete
    useEffect(() => {
        if (deleteClicked) {
            if (deleteSuccess && webauthnKeys.length > 0) {
                setWebauthnKeys(webauthnKeys.slice(0, webauthnKeys.length - 1));
            }
        }
    }, [deleteSuccess])

    return (
        <>
            {loginFormState !== LOGIN_FORM_STATES.ATOZ_DISABLE_WEBAUTHN_STATE && <H2 fontWeight="bold" id='biometrics-step-header'> {t('resources:enableBiometricLogin', webauthnStrings)}</H2>}
            {loginFormState === LOGIN_FORM_STATES.ATOZ_DISABLE_WEBAUTHN_STATE && <H2 fontWeight="bold" id='disable-biometrics-step-header'> {t('resources:disableBiometricLogin', webauthnStrings)}</H2>}
            <Col gridGap='S300'>
                <img width='auto' src={webauthnIcons} alt='BiometricsImage' aria-hidden={true} />
                {errorDisablingCredentials && (
                    <Row
                        gridGap='S200'
                        justifyContent='center'
                        alignItems='center' hidden={errorDisablingCredentials}>
                        <img width='auto' src={ExclamationCircle} alt='ExclamationCircle' />
                        <P fontSize="T200">{t('resources:errorDisablingBiometrics', webauthnStrings)}</P>
                    </Row>)}
                {showErrorCollectingBiometricKey && (
                    <Row
                        gridGap='S200'
                        justifyContent='center'
                        alignItems='center' hidden={showErrorCollectingBiometricKey}>
                        <img width='auto' src={ExclamationCircle} alt='ExclamationCircle' />
                        <P fontSize="T200">{t('resources:errorCapturingBiometrics', webauthnStrings)}</P>
                    </Row>)}
                {showBiometricsNotSupportedError && (
                    <Row
                        gridGap='S200'
                        justifyContent='center'
                        alignItems='center' hidden={showBiometricsNotSupportedError}>
                        <img width='auto' src={ExclamationCircle} alt='ExclamationCircle' />
                        <P fontSize="T200">{capitalizeFirstLetter(t('resources:webauthnNotSupported', webauthnStrings))}</P>
                    </Row>)}
                {loginFormState !== LOGIN_FORM_STATES.ATOZ_DISABLE_WEBAUTHN_STATE && (<Button
                    data-testid="enable-biometrics-button"
                    onClick={() => onClickEnable()}
                    variant={ButtonVariant.Primary}
                >
                    {t('resources:enable')}
                </Button>)}
                {loginFormState === LOGIN_FORM_STATES.BIOMETRICS_STATE && (<Button
                    data-testid="not-now-biometrics-button"
                    onClick={onClickNotNow}
                    variant={ButtonVariant.Secondary}
                >
                    {t('resources:notNow')}
                </Button>)}
                {loginFormState === LOGIN_FORM_STATES.ATOZ_DISABLE_WEBAUTHN_STATE && (<Button
                    data-testid="turn-off-biometrics-button"
                    onClick={onClickTurnOff}
                    variant={ButtonVariant.Primary}
                >
                    {t('resources:turnOff')}
                </Button>)}
                {loginFormState === LOGIN_FORM_STATES.BIOMETRICS_STATE && <P fontSize="T200">{t('resources:biometricLoginFooter' )}</P>}
                {loginFormState === LOGIN_FORM_STATES.ATOZ_ENABLE_WEBAUTHN_STATE && <P fontSize="T200">{t('resources:biometricLoginFooterShortened' )}</P>}
            </Col>
        </>
    )
}