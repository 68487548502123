import { CognitoUser } from 'amazon-cognito-identity-js';

import { ATOZ_TEMPLATE_LABEL, DEFAULT_TEMPLATE_LABEL } from './constants';

const templateLabels = [ATOZ_TEMPLATE_LABEL, DEFAULT_TEMPLATE_LABEL] as const;
export type TemplateLabel = typeof templateLabels[number];

const stages = ['development', 'test', 'alpha', 'beta', 'beta-eu', 'beta-ap', 'gamma', 'prod-na', 'prod-eu', 'prod-ap'] as const;
export type Stage = typeof stages[number];

export const WEBAUTHN_SUPPORTED_VALUES = {
    MANDATORY: 'MANDATORY',
    OPTIONAL: 'OPTIONAL',
    NOT_SUPPORTED: 'NOT_SUPPORTED'
} as const;
export type WebAuthNSupport = typeof WEBAUTHN_SUPPORTED_VALUES[keyof typeof WEBAUTHN_SUPPORTED_VALUES] | undefined;

export type CustomerConfig = {
    userpoolId: string,
    clientId: string,
    region: string,
    links?: { [key: string]: string },
    template?: TemplateLabel,
    emailMFA: boolean,
    oauth?: {
        domain: string,
        providers: string[]
    },
    webauthnSupport?: WebAuthNSupport,
}

export type Locale = {
    name: string,
    value: string,
    puff: string
}


export type StageConfig = {
    [key in Stage]: { [key: string]: CustomerConfig }
}

export interface ChallengeUser extends CognitoUser {
    challengeParam: {
        challenge: string,
        allowCredentials: string
    }
}

export interface WebAuthNCredential {
    userId: string,
    id: string,
    publicKey: string,
    attestationType: string,
    transport: null | string,
    registrationDate: string
    name?: string,
    Flags: {
        userPresent: boolean,
        userVerified: boolean,
        backupEligible: boolean,
        backupState: boolean
    },
    Authenticator: {
        aaguid: string,
        signCount: number,
        cloneWarning: boolean,
        attachment: string
    },
}

export interface CustomerBanner {
    bannerSvg: string,
    altText: string
}

export interface CredentialData {
    id: string;
    type: string;
    rawId: string;
    response: {
        clientDataJSON: string;
        attestationObject: string;
    }
}

const preferredAuthMethods = ['OTP', 'WebAuthN'] as const;
export type PreferredAuthMethod = typeof preferredAuthMethods[number];

export class IdPrismUser extends CognitoUser {
}