import { create } from 'zustand';

interface FeatureFlags {
    isActiveActive: boolean;
    isAllowOtpInPrimaryRegion: boolean;
} 

export const DEFAULT_FEATURE_FLAGS: FeatureFlags = {
    isActiveActive: false,
    isAllowOtpInPrimaryRegion: false
}

interface FeatureFlagState {
  featureFlags: FeatureFlags;

  setFlag<K extends keyof FeatureFlags>(key: K, value: FeatureFlags[K]): void;
  setFlags(flags: Partial<FeatureFlags>): void;
  getFlag<K extends keyof FeatureFlags>(key: K): FeatureFlags[K];
}

export const useFeatureFlagStore = create<FeatureFlagState>()((set, get) => ({
    featureFlags: DEFAULT_FEATURE_FLAGS,

    setFlag: <K extends keyof FeatureFlags>(key: K, value: FeatureFlags[K]) => {
        set((state) => ({ featureFlags: { ...state.featureFlags, [key]: value } }));
    },
    setFlags: (flags: Partial<FeatureFlags>) => {
        set((state) => ({ featureFlags: { ...state.featureFlags, ...flags } }));
    },
    getFlag: <K extends keyof FeatureFlags>(key: K) => {
        return get()?.featureFlags?.[key] || false;
    }
}));
