import { getCustomerConfig } from '../../globals/utils'

// Retrieving the atoz config for customer specific links
export const GET_HELP_LINK = getCustomerConfig()?.links?.getHelp ? getCustomerConfig()?.links?.getHelp :'';
export const A2Z_WELCOME_URI = getCustomerConfig()?.links?.welcomePage ? getCustomerConfig()?.links?.welcomePage :'';
export const PRIVACY_POLICY_LINK = getCustomerConfig()?.links?.privacyPolicy ? getCustomerConfig()?.links?.privacyPolicy : '';
export const SECOND_MILLIS = 1000;
export const MINUTE_MILLIS = SECOND_MILLIS * 60;
export const HOUR_MILLIS = MINUTE_MILLIS * 60;
export const DAY_MILLIS = HOUR_MILLIS * 24;
export const ATOZ_WEBAUTHN_PROMPT_INTERVAL = 7 * DAY_MILLIS;
export const ATOZ_WEBAUTHN_PROMPT_QUOTA = 2;
