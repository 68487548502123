
import { Col, Hr, Row, Spacer, View } from '@amzn/stencil-react-components/esm/layout';
import { IconInformation, IconQuestionCircle } from '@amzn/stencil-react-components/esm/submodules/icons/icons';
import logo from '../../../assets/AtoZ/alumni_logo.png';
import point from '../../../assets/AtoZ/Point.svg';
import LanguageSelector from './LanguageSelector';
import { GET_HELP_LINK, PRIVACY_POLICY_LINK } from '../constants';
import { useTranslation } from 'react-i18next';
import SpinnerOverlay from '../../Default/CustomLoginUI/SpinnerOverlay';
import { useFailoverStore } from '../../../context/failoverContext';
import FailoverBanner from '../../Default/FailoverUI/FailoverBanner';
import { useLoginStore } from '../../../context/loginContext';
import { Link, LinkIconPosition } from '@amzn/stencil-react-components/esm/link';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/esm/message-banner';
import { useEffect, useRef } from 'react';

interface CommonLoginViewProps {
    content: JSX.Element;
}

export default function CommonLoginView(props: CommonLoginViewProps) {
    const { t } = useTranslation();
    const { content } = props;
    const showSpinnerOverlay = useLoginStore((state) => state.showSpinnerOverlay);
    const setShowSuccessResetPasswordBanner = useLoginStore((state) => state.setShowSuccessResetPasswordBanner); 
    const showSuccessResetPasswordBanner = useLoginStore((state) => state.showSuccessResetPasswordBanner); 
    const { userPoolId: failoverPoolId, isFailover } = useFailoverStore().failoverPoolConfig;

    const resetPasswordSuccessRef = useRef(null);

    useEffect(() => {
        if (showSuccessResetPasswordBanner) {
            //@ts-expect-error TS doesn't seem to recognize that this current field might change
            resetPasswordSuccessRef.current.focus();
        }
    }, [showSuccessResetPasswordBanner])

    return (
        <Col
            minHeight='95vh'
            justifyContent='center'
            alignItems='stretch'
            gridGap='S100'
            padding='S100'
        >
            {showSuccessResetPasswordBanner &&
            <MessageBanner
                isDismissible
                onDismissed={() => setShowSuccessResetPasswordBanner(false)}
                type={MessageBannerType.Success}
                aria-live='polite'
                ref={resetPasswordSuccessRef}
                >
                {t('resources:passwordResetSuccess')}
            </MessageBanner>
        }
            <Spacer flex={2} />
            <View alignSelf='center' flex={5} minWidth='300px' maxWidth='90%'>
                <SpinnerOverlay active={showSpinnerOverlay}>
                    <img width='auto' height={40} src={logo} alt='Amazon AtoZ' />
                    <>
                        {failoverPoolId != "" && isFailover &&
                            <FailoverBanner />
                        }
                    </>
                    <Spacer height={16} />
                    {content}
                    <View display='flex' alignItems='center' style={{marginTop: '100px', marginBottom: '16px'}}>
                        <View flex={4}><Hr color='neutral10' size='wide'/></View>
                    </View>
                    <Row
                        justifyContent='center'
                        alignItems='center'
                        flexWrap='wrap'
                        gridGap='S300'
                    >
                        <LanguageSelector />
                        <img src={point}/>
                        <Link
                            href={GET_HELP_LINK}
                            target='_blank'
                            icon={<IconQuestionCircle color='neutral70' />}
                            iconPosition={LinkIconPosition.Leading}
                            textDecoration={'0'}
                            color='neutral70'
                        >
                            {t('resources:getHelp')}
                        </Link>
                        <img src={point}/>
                        {PRIVACY_POLICY_LINK && 
                            <Link
                                href={PRIVACY_POLICY_LINK}
                                target='_blank'
                                icon={<IconInformation color='neutral70'/>}
                                iconPosition={LinkIconPosition.Leading}
                                textDecoration={'0'}
                                color='neutral70'
                            >
                                {t('resources:privacyPolicy')}
                            </Link>
                        }
                    </Row>
                </SpinnerOverlay>
            </View>
            <Spacer flex={2} />
        </Col>
    );
}
