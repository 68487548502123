import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// Bundle i18n
import './localization/i18n';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement
);
