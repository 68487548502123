import { create } from 'zustand';

interface RoutingDetails {
    primaryUserPoolId: string;
    activeActive: boolean;
}

export const DEFAULT_ROUTING_CONTEXT_STATE: RoutingDetails = {
    primaryUserPoolId: "",
    activeActive: false
}

interface ActiveActiveState {
    routingDetails: RoutingDetails;
    setRoutingDetails: (routingContext: RoutingDetails) => void;
}

export const useRoutingStore = create<ActiveActiveState>()((set) => ({
    routingDetails: DEFAULT_ROUTING_CONTEXT_STATE,
    setRoutingDetails: (routingDetails: RoutingDetails) => {
        set(() => ({ routingDetails: routingDetails }));
    },
}));
