import { AxiosError } from "axios";
import { REQUEST_ID_HEADER } from "./constants";

export interface IdPrismErrorProps {
    transactionId?: string;
    error?: Error;
    message?: string;
}

export class IdPrismError extends Error {
    transactionId?: string;

    constructor({ error, transactionId, message }: IdPrismErrorProps) {
        super(message || error?.message || 'An error occurred', {cause: error});
        this.transactionId = transactionId;
    }

    setMessage(message: string) {
        this.message = message;
    }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IdPrismHttpErrorProps<T=any,D=any> {
    axiosError: AxiosError<T,D>;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export class IdPrismHttpError<T=any,D=any> extends IdPrismError {
    httpError: AxiosError<T,D>;
    statusCode: number;

    constructor({ axiosError }: IdPrismHttpErrorProps) {
        super(
            {
                //We check the response header first, in case the backend lambda isn't reading our transactionId header
                transactionId: axiosError.response?.config?.headers?.[REQUEST_ID_HEADER] || axiosError.config?.headers[REQUEST_ID_HEADER],
                error: axiosError,
            }
        )
        this.httpError = axiosError;
        this.statusCode = axiosError.response?.status || 500;
        let message = "";
        if(axiosError.response?.data) {
            message += JSON.stringify(axiosError.response?.data);
        } else {
            message += axiosError.message
        }
        this.setMessage(message);
    }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function isIdPrismHttpError(error: any): error is IdPrismHttpError {
    return error instanceof IdPrismHttpError;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function isIdPrismError(error: any): error is IdPrismError {
    return error instanceof IdPrismError;
}