import {
  Button,
  ButtonVariant,
} from '@amzn/stencil-react-components/esm/button';
import { Col } from '@amzn/stencil-react-components/esm/layout';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/esm/message-banner';
import { H3 } from '@amzn/stencil-react-components/esm/text';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LOGIN_FORM_STATES, useLoginStore } from '../../../context/loginContext';
import i18n from '../../../localization/i18n';

export default function LoggedInStep() {
  const { t } = useTranslation();
  const clearLoginState = useLoginStore((state) => state.clearLoginState);
  const errorRedirecting = useLoginStore((state) => state.errorRedirecting);
  const [showError, setShowError] = useState(false);
  const setLoginFormState = useLoginStore((state) => state.setLoginFormState);
  const webauthnEnabled = useLoginStore((state) => state.webauthnEnabled);

  const onClickLogout = async () => {
    await Auth.signOut();
    clearLoginState();
  };

  useEffect(() => {
    if (errorRedirecting) {
      setShowError(true);
    }
  }, [errorRedirecting])

  return (
    <>
      {showError &&
        <MessageBanner
          isDismissible
          onDismissed={() => setShowError(false)}
          type={MessageBannerType.Error}
          data-testid='error-redirecting-banner'
        >
          {i18n.t('resources:errorRedirecting')}
        </MessageBanner >
      }
      <Col gridGap='S200'>
        <H3>{t('resources:signedIn')}</H3>
        <Button onClick={onClickLogout} variant={ButtonVariant.Primary} data-testid='logout-button'>
          {t('resources:logout')}
        </Button>
        {webauthnEnabled &&
          <Button onClick={() => setLoginFormState(LOGIN_FORM_STATES.CREDENTIAL_MANAGEMENT)} variant={ButtonVariant.Primary} data-testid='manage-credentials-button' >
            {t('resources:manageCredentials')}
          </Button>
        }
      </Col>
    </>
  );
}
