import {
  Button,
  ButtonVariant,
} from '@amzn/stencil-react-components/esm/button';
import { Checkbox, Input, InputFooter } from '@amzn/stencil-react-components/esm/form';
import { Col, Row, Spacer } from '@amzn/stencil-react-components/esm/layout';
import { IconAlertCircleFill, IconCheckCircleFill, IconRecord } from '@amzn/stencil-react-components/esm/submodules/icons/icons';
import { H3, Label, Text } from '@amzn/stencil-react-components/esm/text';
import { yupResolver } from '@hookform/resolvers/yup';
import { Auth } from 'aws-amplify';
import i18n from 'i18next';
import { useState } from 'react';
import { Controller, MultipleFieldErrors, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LOGIN_FORM_STATES, useLoginStore } from '../../../context/loginContext';
import { EVENT_KEY_ENTER } from '../../../globals/constants';
import { constructPasswordSchema } from '../../../globals/utils';

const COGNITO_SMS_MFA_CHALLENGE_LABEL = 'SMS_MFA';

export default function FirstPasswordStep() {
  const { t } = useTranslation();
  const setLoginFormState = useLoginStore((state) => state.setLoginFormState);
  const setCognitoUser = useLoginStore((state) => state.setCognitoUser);
  const intermediateUser = useLoginStore((state) => state.intermediateUser);
  const setErrorRedirecting = useLoginStore((state) => state.setErrorRedirection);
  const setPassword = useLoginStore((state) => state.setPassword);
  const [newPasswordFirstSignIn, setNewPasswordFirstSignIn] = useState('');
  const [
    newPasswordFirstSignInConfirmation,
    setNewPasswordFirstSignInConfirmation,
  ] = useState('');
  const [showPasswordChecked, setShowPasswordChecked] = useState(false);
  const [passwordFieldBlurred, setPasswordFieldBlurred] = useState(false)
  const [confirmPasswordBlurred, setConfirmedPasswordBlurred] = useState(false)
  const setShowSpinnerOverlay = useLoginStore((state) => state.setShowSpinnerOverlay);
  const promptRegisterWebauthn = useLoginStore((state) => state.promptRegisterWebauthn);

  const { control, formState: { errors, isDirty }, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(constructPasswordSchema(i18n)),
    criteriaMode: 'all'
  });

  const onClickSubmitNewPasswordFirstSignIn = async () => {
    setShowSpinnerOverlay(true);
    try {
      const newPassIntermediateUser = await Auth.completeNewPassword(
        intermediateUser,
        newPasswordFirstSignIn
      );
      if (newPassIntermediateUser.challengeName === COGNITO_SMS_MFA_CHALLENGE_LABEL) {
        setPassword(newPasswordFirstSignIn);
        setLoginFormState(LOGIN_FORM_STATES.MFA_STATE);
      } else if (promptRegisterWebauthn) {
        setLoginFormState(LOGIN_FORM_STATES.WEB_AUTHN_STATE)
      } else {
        setCognitoUser(newPassIntermediateUser);
        setLoginFormState(LOGIN_FORM_STATES.SPINNER);
      }
    } catch (err) {
      // TODO update error handling (requestIds)
      setErrorRedirecting(true);
      console.error(err)
    } finally {
      setShowSpinnerOverlay(false);
    }
  };

  const getIconsForSpecialRequirements = () => {
    if (!isDirty || !passwordFieldBlurred) {
      return (<>
        <IconRecord color="neutral10" title="Requirement" margin={{ right: 10 }} />
        {t('resources:passwordSpecialCharactersRequirement')}
      </>)
    } else if (Object.hasOwn(errors?.password?.types ?? ({} as MultipleFieldErrors), "matches")) {
      return (
        <>
          <IconAlertCircleFill color="red" title="Requirement" margin={{ right: 10 }} />
          {t('resources:passwordSpecialCharactersRequirement')}
        </>
      )
    } else {
      return (
        <>
          <IconCheckCircleFill color="green" title="Requirement" margin={{ right: 10 }} />
          {t('resources:passwordSpecialCharactersRequirement')}
        </>
      )
    }
  }

  const getIconsForPasswordLength = () => {
    if (!isDirty || !passwordFieldBlurred) {
      return (<>
        <IconRecord color="neutral10" title="Requirement" margin={{ right: 10 }} />
        {t('resources:passwordLengthRequirement')}
      </>)
    } else if (Object.hasOwn(errors?.password?.types ?? ({} as MultipleFieldErrors), "min") || Object.hasOwn(errors?.password?.types ?? ({} as MultipleFieldErrors), "max")) {
      return (
        <>
          <IconAlertCircleFill color="red" title="Requirement" margin={{ right: 10 }} />
          {t('resources:passwordLengthRequirement')}
        </>
      )
    } else {
      return (
        <>
          <IconCheckCircleFill color="green" title="Requirement" margin={{ right: 10 }} />
          {t('resources:passwordLengthRequirement')}
        </>
      )
    }
  }

  return (
    <form
      onSubmit={handleSubmit(() => null, () => null)}
      onKeyDown={(event) => event.key == EVENT_KEY_ENTER && event.preventDefault()}
    >
      <H3 id='login-step-header'>{t('resources:enterNewPassword')}</H3>
      <Spacer
        height={16}
      />
      <Text
        fontWeight="bold"
        fontSize="T200"
        textAlign="left"
      >
        {t('resources:passwordRequirementsHeading')}
      </Text>
      <Spacer
        height={10}
      />
      <Text
        fontSize="T200"
        textAlign="left"
      >
        {t('resources:passwordRequirementsGeneral')}
      </Text>
      <Text
        fontSize="T200"
        textAlign="left"
      >
        <Row>
          {getIconsForPasswordLength()}
        </Row>
        <Row>
          {getIconsForSpecialRequirements()}
        </Row>
      </Text>
      <Col gridGap='S200'>
        <Col>
          <Label htmlFor='input-id-4'>{t('resources:password')}</Label>
          <Controller
            name={'password'}
            control={control}
            render={({ field: { ref, name, onBlur, onChange } }) => (
              <Input
                id='input-id-4'
                name={name}
                ref={ref}
                value={newPasswordFirstSignIn}
                type={showPasswordChecked ? 'text' : 'password'}
                autoComplete='temporary-password'
                onChange={(event) => {
                  onChange(event.target.value); setNewPasswordFirstSignIn(event.target.value)
                }}
                onBlur={() => { onBlur(); setPasswordFieldBlurred(true) }}
              />
            )}
          />
          {(errors?.password?.message as string)?.length > 0 &&
            <InputFooter id='input-footer-6' error>
              {errors?.password?.message}
            </InputFooter>
          }
        </Col>
        <Col>
          <Label htmlFor='input-id-4'>{t('resources:confirmPassword')}</Label>
          <Input
            id='input-id-4'
            name='password'
            type={showPasswordChecked ? 'text' : 'password'}
            autoComplete='new-password'
            value={newPasswordFirstSignInConfirmation}
            onChange={(event) =>
              setNewPasswordFirstSignInConfirmation(event.target.value)
            }
            onBlur={() => setConfirmedPasswordBlurred(true)}
          />
          {((
            newPasswordFirstSignInConfirmation !==
            newPasswordFirstSignIn
          ) && newPasswordFirstSignInConfirmation.length > 0 && confirmPasswordBlurred) &&
            <InputFooter id='input-footer-6' error>
              {t('resources:passwordNotMatchError')}
            </InputFooter>
          }
        </Col>
        <Row gridGap='S200' alignItems='center'>
          <Checkbox
            onChange={(event) => setShowPasswordChecked(event.target.checked)}
            checked={showPasswordChecked}
            name='checkbox'
            id='setShowPasswordCheckBox'
          />
          <Label htmlFor='setShowPasswordCheckBox'>{t('resources:showPassword')}</Label>
        </Row>
        <Button
          disabled={
            !(
              newPasswordFirstSignInConfirmation ===
              newPasswordFirstSignIn && newPasswordFirstSignIn
            )
          }
          onClick={onClickSubmitNewPasswordFirstSignIn}
          style={{ width: '100%' }}
          type='submit'
          variant={ButtonVariant.Primary}
        >
          {t('resources:continue')}
        </Button>
      </Col>
    </form>
  );
}
