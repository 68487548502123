import { H2, P, Text } from '@amzn/stencil-react-components/esm/text';
import { Col, Row } from '@amzn/stencil-react-components/esm/layout';
import { useTranslation } from 'react-i18next';
import { LOGIN_FORM_STATES, useLoginStore } from '../../../context/loginContext';
import GreenCheck from '../../../assets/GreenCheck.svg';
import { useWebauthnStrings } from '../../../hooks/webauthnHooks';
import { capitalizeFirstLetter } from '../../../globals/utils';

export default function WebauthnStep() {
    const { t } = useTranslation();
    const loginFormState = useLoginStore((state) => state.loginFormState);
    const webauthnStrings = useWebauthnStrings();

    return (
        <>
            <Col gridGap='S200'>
                <Row justifyContent='center'><img src={GreenCheck} sizes='64px' aria-hidden={true}/></Row>
                {loginFormState === LOGIN_FORM_STATES.ATOZ_WEBAUTHN_ENABLED_STATE &&
                    <>
                        <H2 textAlign='center' fontWeight="bold" id='biometrics-enabled-header'> {t('resources:biometricLoginTurnedOn', webauthnStrings)}</H2>
                        <Text textAlign='center' fontSize="T200">{t('resources:biometricLoginInstructions', webauthnStrings)}</Text>
                    </>
                 }
                {loginFormState === LOGIN_FORM_STATES.ATOZ_WEBAUTHN_DISABLED_STATE && 
                    <>
                        <H2 textAlign='center' fontWeight="bold" id='biometrics-enabled-header'> {capitalizeFirstLetter(t('resources:biometricLoginTurnedOff', webauthnStrings))}</H2>
                        <P textAlign='center' fontSize="T200">{t('resources:biometricLoginTurnedOffDetails', webauthnStrings)}</P>
                    </>
                 }
                 <Text textAlign='center' fontSize="T200">
                    {t('resources:closeWindowPrompt')}
                </Text>
            </Col>
        </>
    )
}