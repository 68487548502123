import { useState } from 'react';

import {
  Button,
  ButtonVariant,
} from '@amzn/stencil-react-components/esm/button';
import { Checkbox, Input, InputFooter } from '@amzn/stencil-react-components/esm/form';
import { Col, Row, Spacer } from '@amzn/stencil-react-components/esm/layout';
import { IconAlertCircleFill, IconCheckCircleFill, IconRecord } from '@amzn/stencil-react-components/esm/submodules/icons/icons';
import { H3, Label, Text } from '@amzn/stencil-react-components/esm/text';
import { yupResolver } from '@hookform/resolvers/yup';
import { Auth } from 'aws-amplify';
import i18n from 'i18next';
import { Controller, MultipleFieldErrors, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LOGIN_FORM_STATES, useLoginStore } from '../../../context/loginContext';
import { EVENT_KEY_ENTER, PASSWORD_ATTEMPTS_EXCEEDED, POST_CONFIRMATION_FAILED } from '../../../globals/constants';
import { constructPasswordSchema } from '../../../globals/utils';

export default function ForgotPasswordStep() {
  const { t } = useTranslation();
  const { control, formState: { errors, isDirty }, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(constructPasswordSchema(i18n)),
    criteriaMode: 'all'
  });

  const username = useLoginStore((state) => state.username);
  const clearLoginState = useLoginStore((state) => state.clearLoginState);
  const setLoginFormState = useLoginStore((state) => state.setLoginFormState);
  const setErrorResettingPassword = useLoginStore((state) => state.setErrorResettingPassword);

  // Used for Setting Password after clicking Forgot Password
  const [passwordResetCode, setPasswordResetCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [
    showIncorrectResetPasswordError,
    setShowIncorrectResetPasswordError,
  ] = useState(false);
  const [passwordFieldBlurred, setPasswordFieldBlurred] = useState(false);
  const [confirmPasswordBlurred, setConfirmedPasswordBlurred] = useState(false);
  const [showPasswordChecked, setShowPasswordChecked] = useState(false);
  const [
    newPasswordConfirmation,
    setNewPasswordConfirmation,
  ] = useState('');
  const setShowSpinnerOverlay = useLoginStore((state) => state.setShowSpinnerOverlay);

  const onClickSubmitNewPasswordWithCode = async () => {
    setShowSpinnerOverlay(true);
    try {
      await Auth.forgotPasswordSubmit(username, passwordResetCode, newPassword);
      clearLoginState();
    } catch (error) {
      const authError = error as Error;
      if (authError.message === PASSWORD_ATTEMPTS_EXCEEDED) {
        setLoginFormState(LOGIN_FORM_STATES.TOO_MANY_FAILED_AUTH);
      } else if (authError.message.includes(POST_CONFIRMATION_FAILED)) {
        setErrorResettingPassword(true);
        setLoginFormState(LOGIN_FORM_STATES.USERNAME_STATE);
      } else {
        setShowIncorrectResetPasswordError(true);
      }
    } finally {
      setShowSpinnerOverlay(false);
    }
  };

  const getIconsForSpecialRequirements = () => {
    if (!isDirty || !passwordFieldBlurred) {
      return (<>
        <IconRecord color="neutral10" title="Requirement" />
        <Spacer
          width={10}
        />
        {t(`resources:passwordSpecialCharactersRequirement`)}
      </>)
    } else if (Object.hasOwn(errors?.password?.types ?? ({} as MultipleFieldErrors), "matches")) {
      return (
        <>
          <IconAlertCircleFill color="red" title="Requirement" />
          <Spacer
            width={10}
          />
          {t(`resources:passwordSpecialCharactersRequirement`)}
        </>
      )
    } else {
      return (
        <>
          <IconCheckCircleFill color="green" title="Requirement" />
          <Spacer
            width={10}
          />
          {t(`resources:passwordSpecialCharactersRequirement`)}
        </>
      )
    }
  }

  const getIconsForPasswordLength = () => {
    if (!isDirty || !passwordFieldBlurred) {
      return (<>
        <IconRecord color="neutral10" title="Requirement" />
        <Spacer
          width={10}
        />
        {t(`resources:passwordLengthRequirement`)}
      </>)
    } else if (Object.hasOwn(errors?.password?.types ?? ({} as MultipleFieldErrors), "min") || Object.hasOwn(errors?.password?.types ?? ({} as MultipleFieldErrors), "max")) {
      return (
        <>
          <IconAlertCircleFill color="red" title="Requirement" />
          <Spacer
            width={10}
          />
          {t(`resources:passwordLengthRequirement`)}
        </>
      )
    } else {
      return (
        <>
          <IconCheckCircleFill color="green" title="Requirement" />
          <Spacer
            width={10}
          />
          {t(`resources:passwordLengthRequirement`)}
        </>
      )
    }
  }

  return (
    <form
      onSubmit={handleSubmit(() => null, () => null)}
      onKeyDown={(event) => event.key == EVENT_KEY_ENTER && event.preventDefault()}
    >
      <H3 id='login-step-header'>{t(`resources:setNewPassword`)}</H3>
      <Col>
        <Label htmlFor='input-id-1'>
          {t(`resources:enterPasswordResetCode`)}
        </Label>
        <Input
          id='input-id-1'
          data-testid='reset-password-code'
          name='password'
          type='text'
          autoComplete='reset-password-code'
          value={passwordResetCode}
          onChange={(event) => setPasswordResetCode(event.target.value.trim())}
        />
        {showIncorrectResetPasswordError && (
          <InputFooter id='input-footer-1' error>
            {t(`resources:incorrectResetCode`)}
          </InputFooter>
        )}
      </Col>
      <Spacer
        height={16}
      />
      <Text
        fontWeight="bold"
        fontSize="T200"
        textAlign="left"
      >
        {t(`resources:passwordRequirementsHeading`)}
      </Text>
      <Spacer
        height={10}
      />
      <Text
        fontSize="T200"
        textAlign="left"
      >
        {t(`resources:passwordRequirementsGeneral`)}
      </Text>
      <Text
        fontSize="T200"
        textAlign="left"
      >
        <Row>
          {getIconsForPasswordLength()}
        </Row>
        <Row>
          {getIconsForSpecialRequirements()}
        </Row>
      </Text>
      <Spacer
        height={16}
      />
      <Col gridGap='S200'>
        <Col>
          <Label htmlFor='input-id-2'>{t(`resources:setNewPassword`)}</Label>
          <Controller
            name='password'
            control={control}
            render={({ field: { ref, name, onBlur, onChange } }) => (
              <Input
                id='input-id-2'
                data-testid='reset-password-new'
                name={name}
                ref={ref}
                value={newPassword}
                type={showPasswordChecked ? 'text' : 'password'}
                autoComplete='new-password'
                onChange={(event) => {
                  onChange(event.target.value); setNewPassword(event.target.value)
                }}
                onBlur={() => { onBlur(); setPasswordFieldBlurred(true) }}
              />
            )}
          />
          {(errors?.password?.message as string)?.length > 0 &&
            <InputFooter id='input-footer-2' error>
              {errors?.password?.message}
            </InputFooter>
          }
        </Col>
        <Col>
          <Label htmlFor='input-id-3'>{t(`resources:confirmPassword`)}</Label>
          <Input
            id='input-id-3'
            data-testid='reset-password-confirm'
            name='password'
            type={showPasswordChecked ? 'text' : 'password'}
            autoComplete='confirm-password'
            value={newPasswordConfirmation}
            onChange={(event) =>
              setNewPasswordConfirmation(event.target.value)
            }
            onBlur={() => setConfirmedPasswordBlurred(true)}
          />
          {((
            newPasswordConfirmation !==
            newPassword
          ) && newPasswordConfirmation.length > 0 && confirmPasswordBlurred) &&
            <InputFooter id='input-footer-3' error>
              {t(`resources:passwordNotMatchError`)}
            </InputFooter>
          }
        </Col>
        <Row gridGap='S200' alignItems='center'>
          <Checkbox
            onChange={(event) => setShowPasswordChecked(event.target.checked)}
            checked={showPasswordChecked}
            name='checkbox'
            id='setShowPasswordCheckBox'
          />
          <Label htmlFor='setShowPasswordCheckBox'>{t(`resources:showPassword`)}</Label>
        </Row>
        <Button
          disabled={!(passwordResetCode && newPassword) || (errors?.password?.message as string)?.length > 0 || newPasswordConfirmation !== newPassword}
          onClick={onClickSubmitNewPasswordWithCode}
          style={{ width: '100%' }}
          variant={ButtonVariant.Primary}
        >
          {t(`resources:continue`)}
        </Button>
        <Button onClick={clearLoginState} style={{ width: '100%' }}>
          {t(`resources:back`)}
        </Button>
      </Col>
    </form>
  );
}
