import { Button, ButtonVariant } from '@amzn/stencil-react-components/esm/button';
import { FlyoutContent, RenderFlyoutFunction, WithFlyout } from '@amzn/stencil-react-components/esm/flyout';
import { Radio } from '@amzn/stencil-react-components/esm/form';
import { Row } from '@amzn/stencil-react-components/esm/layout';
import { Label } from '@amzn/stencil-react-components/esm/text';
import { useState } from 'react';
import i18n from 'i18next';
import { createLangMap } from '../localization/i18nConfig';
import { useTranslation } from 'react-i18next';
import { Link, LinkIconPosition } from '@amzn/stencil-react-components/esm/link';
import { IconGlobe } from '@amzn/stencil-react-components/esm/submodules/icons';

export default function languageSelector() {
  const { t } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const changeLanguageHandler = (lang: string) => () => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  const languageList = Array.from(createLangMap()).map((entry, index) => {
    return (
      <Row key={index} gridGap='S200' padding='S200' alignItems='center'>
        <Radio
          key={index}
          id={entry[0]}
          name={'radio-' + entry[0]}
          value={entry[1]}
          onChange={changeLanguageHandler(entry[0])}
          checked={language === entry[0]}
        />
        <Label htmlFor={'radio-' + entry[0]}>{entry[1]}</Label>
      </Row>
    );
  })

  const renderFlyout: RenderFlyoutFunction = ({ close }) => (
    <FlyoutContent
      titleText={t('resources:selectLanguageTitle') || ''}
      onCloseButtonClick={close}
      buttons={[
        <Button key={'chooseLanguageFlyout'} onClick={close} variant={ButtonVariant.Primary}>
          {t('resources:confirmChangeLanguage')}
        </Button>
      ]}>
      {languageList}
    </FlyoutContent>
  );

  return (
    <WithFlyout renderFlyout={renderFlyout}>
      {({ open }) => (
        <Link
            onClick={() => open()}
            target='_blank'
            icon={<IconGlobe color='neutral70'/>}
            iconPosition={LinkIconPosition.Leading}
            textDecoration={'0'}
            color='neutral70'
        >
          {t('resources:changeLanguage')}
        </Link>
      )}
    </WithFlyout>
  );
}