import i18n, { Resource } from 'i18next';
import LanguageDetector, { DetectorOptions } from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { createResourceMap as a2zResourceMap } from '../templates/AtoZ/localization/i18nConfig';
import { createResourceMap as defaultResourceMap } from '../templates/Default/localization/i18nConfig';
import { ATOZ_TEMPLATE_LABEL } from '../globals/constants';
import { getCustomerConfig } from '../globals/utils'

const detectionOptions: DetectorOptions = {
  // order and from where user language should be detected
  order: ['localStorage', 'sessionStorage', 'querystring', 'navigator'],

  // keys or params to lookup language from
  lookupQuerystring: 'ui_locales',
  lookupCookie: 'i18nextLng',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',

  // cache user language on
  caches: ['localStorage'],
  //https://www.i18next.com/how-to/faq#process can use this for tests
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
}

let resources: Resource;
const template = getCustomerConfig().template;
switch (template) {
  case ATOZ_TEMPLATE_LABEL:
    resources =  a2zResourceMap();
    break;
  default:
   resources = await defaultResourceMap()
    break;
}

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  //using default options for language detection, can be configured https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: detectionOptions
  });

export default i18n;
