import {
  Button,
  ButtonVariant,
} from '@amzn/stencil-react-components/esm/button';
import { Input } from '@amzn/stencil-react-components/esm/form';
import { Col, Hr, Row } from '@amzn/stencil-react-components/esm/layout';
import { H3, Label, P } from '@amzn/stencil-react-components/esm/text';
import { Auth } from 'aws-amplify';
import { Fragment, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFailoverStore } from '../../../context/failoverContext';
import { LOGIN_FORM_STATES, useLoginStore } from '../../../context/loginContext';
import { FEDERATE_PARAMS as AMAZON_FEDERATE_PARAMS, FEDERATION_INITIATED, PASSWORD_PROVIDER } from '../../../globals/constants';
import { CustomerConfig } from '../../../globals/types';
import { getCustomerConfig } from '../../../globals/utils';
import { Link } from '@aws-amplify/ui-react';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/esm/message-banner';

export default function UsernameStep() {
  const { t } = useTranslation();
  const setLoginFormState = useLoginStore((state) => state.setLoginFormState);
  const setUsername = useLoginStore((state) => state.setUsername);
  const username = useLoginStore((state) => state.username);
  const errorRedirecting = useLoginStore((state) => state.errorRedirecting);
  const setErrorRedirecting = useLoginStore((state) => state.setErrorRedirection);
  const errorResettingPassword = useLoginStore((state) => state.errorResettingPassword);
  const setErrorResettingPassword = useLoginStore((state) => state.setErrorResettingPassword);
  const { userPoolId: failoverPoolId } = useFailoverStore().failoverPoolConfig;
  const [passwordAllowed, setPasswordAllowed] = useState(true);
  const [showErrorRedirectingBanner, setShowErrorRedirectingBanner] = useState(false);

  const customerConfig: CustomerConfig = getCustomerConfig();
  const TRUE = 'true';
  const FALSE = 'false';

  const onClickSubmitUsername = async () => {
    if (failoverPoolId != "") {
      setUsername(username);
      setLoginFormState(LOGIN_FORM_STATES.FAILOVER_EMAIL_OTP);
    } else {
      setLoginFormState(LOGIN_FORM_STATES.PASSWORD_STATE);
    }
  }

  // Carries out the federated sign-in flow
  const federatedIdpSignIn = async (provider: string) => {
    // Storing query params passed to us by Amazon Federate (not to be confused with the federated provider)
    localStorage.setItem(AMAZON_FEDERATE_PARAMS, window.location.search);
    // Marking in local storage that we have initiated a federation to an IdP
    localStorage.setItem(FEDERATION_INITIATED, TRUE);
    // federateSignIn does return an ICredential but as these calls result in a redirect we are unable to capture that
    await Auth.federatedSignIn(
      { customProvider: provider }
    ).catch(error => {
      setErrorRedirecting(true);
      console.error(error)
    });
  }

  /*
  * Check for oauth configuration and set up the username screen accordingly
  * If there is only one federated provider configured and password is not allowed, this goes directly to the federated sign in
  */
  const configureOauthProviders = () => {
    if (customerConfig.oauth && customerConfig.oauth.providers.length > 0) {
      // If there is only one and it isn't password, we can skip getting input from the user and go directly to the custom provider
      // If FEDERATION_INITIATED is not true then we have not already redirected (this prevents an infinite loop of redirection since this piece of code runs before the doAuthCall redirect in Login.tsx)
      if (customerConfig.oauth.providers.length === 1 && customerConfig.oauth.providers[0] !== PASSWORD_PROVIDER && localStorage.getItem(FEDERATION_INITIATED) !== TRUE) {
        federatedIdpSignIn(customerConfig.oauth.providers[0]);
      } else {
        // Setting FEDERATION_INITIATED to false to allow a subsequent login with a single provider configured to be redirected
        localStorage.setItem(FEDERATION_INITIATED, FALSE);
      }

      // Checking whether we want to allow the password flow
      // This will only run when oauth is configured so by default each customer will allow password
      if (!customerConfig.oauth.providers.includes(PASSWORD_PROVIDER)) {
        setPasswordAllowed(false)
      }
    }
  }

  useEffect(() => {
    configureOauthProviders();
  }, []);

  useEffect(() => {
    if (errorRedirecting) {
      setShowErrorRedirectingBanner(true);
    }
  }, [errorRedirecting])

  // Building a list of buttons for each federation idp
  const getFederationButtons = (providers: string[]) => {
    providers = providers.filter((prov) => prov !== PASSWORD_PROVIDER);
    return providers.map((prov, index) => (
      <Fragment key={index}>
        {
          <Row
            style={{ marginTop: 10 }}
            gridGap='S200'
            justifyContent='center'
            alignItems='center'
          >
            <Button
              data-testid={`login-with-${prov}`}
              onClick={() => federatedIdpSignIn(prov)}
              style={{ width: '100%' }}
              variant={ButtonVariant.Primary}
            >
              {t('resources:signInWith')} {t(`resources:${prov}`)}
            </Button>
          </Row>
        }
      </Fragment>
    ));
  }

  return (
    <>
      {showErrorRedirectingBanner &&
        <MessageBanner
          isDismissible
          onDismissed={() => setShowErrorRedirectingBanner(false)}
          type={MessageBannerType.Error}
        >
          {t('resources:errorRedirecting')}
        </MessageBanner >
      }
      {errorResettingPassword &&
        <MessageBanner
          isDismissible
          onDismissed={() => setErrorResettingPassword(false)}
          type={MessageBannerType.Warning}
        >
          {t('resources:errorDeletingPasskeys')}
        </MessageBanner >
      }
      <H3 id='login-step-header'> {t('resources:logIn')}</H3>
      <Col gridGap='S200'>
        <Label htmlFor='input-id-4' hidden={!passwordAllowed}>{t('resources:logInLabel')}</Label>
        {passwordAllowed && (<Input
          data-testid="username-input"
          id='input-id-4'
          autoComplete='current-username'
          value={username}
          type='text'
          autoCapitalize='none'
          autoCorrect='off'
          onChange={(event) => setUsername(event.target.value)}
        />)}
        {passwordAllowed && (<Row
          style={{ marginTop: 10 }}
          gridGap='S200'
          justifyContent='center'
          alignItems='center'
        >
          <Button
            data-testid="submit-username-button"
            disabled={!username}
            onClick={onClickSubmitUsername}
            style={{ width: '100%' }}
            variant={ButtonVariant.Primary}
          >
            {t('resources:continue')}
          </Button>
        </Row>)}
        {customerConfig.oauth && customerConfig.oauth.providers.length >= 1 && (
          <>
            <Hr size='wide' style={{ marginTop: 10 }} hidden={!passwordAllowed} data-testid="federate-buttons-line-break"/>
            {getFederationButtons(customerConfig.oauth.providers!)}
          </>
        )}
        {customerConfig.links && customerConfig.links.selfSignUp && (
          <P fontSize={14} textAlign='center' data-testid='self-signup-link'>
            <Trans i18nKey='resources:selfSignUp'>
              {/* This is not the text that gets rendered. It just serves as a template for placing the link within the i18n string */}
              Don&apost have an account? <Link  href={customerConfig.links?.selfSignUp} target="_blank">Create one here</Link>
            </Trans>
          </P>
        )}
        {customerConfig.links && customerConfig.links.termsOfUse && (
          <P fontSize={14}>
            <Trans i18nKey='resources:termsOfUse'>
              {/* This is not the text that gets rendered. It just serves as a template for placing the link within the i18n string */}
              By continuing, you agree to Amazon&aposs <Link  href={customerConfig.links?.termsOfUse} target="_blank">Terms of Use</Link>.
            </Trans>
          </P>
        )}
      </Col>
    </>
  );
}
