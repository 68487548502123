import React, { useEffect } from "react";
import { LOGIN_FORM_STATES, useLoginStore } from "../../../context/loginContext";
import useWebauthnIcons, { useWebauthnStrings } from "../../../hooks/webauthnHooks";
import { ChallengeUser, IdPrismUser } from "../../../globals/types";
import { GetWebauthnChallengeAnswer } from "../../../globals/utils";
import { Auth } from "aws-amplify";
import { CognitoUser } from 'amazon-cognito-identity-js';
import { FAILED_TO_GET_WEBAUTHN_CHALLENGE_ANSWER, INVALID_PARAMETER_EXCEPTION, LAMBDA_VALIDATION_EXCEPTION, NOT_ALLOWED_ERROR } from '../../../globals/constants';
import { t } from "i18next";
import { Button, ButtonIconPosition, ButtonSize, ButtonVariant } from "@amzn/stencil-react-components/esm/button";

export const WebAuthnErrors = {
    BIOMETRICS_NOT_SUPPORTED: "BIOMETRICS_NOT_SUPPORTED",
    INCORRECT_WEBAUTHN: "INCORRECT_WEBAUTHN",
    FAILED_TO_GET_WEBAUTHN_CHALLENGE_ANSWER: "FAILED_TO_GET_WEBAUTHN_CHALLENGE_ANSWER",
    NO_CREDENTIALS_REGISTERED: "NO_CREDENTIALS_REGISTERED",
    NO_USERNAME_ENTERED: "NO_USERNAME_ENTERED",
    UNKNOWN: "UNKNOWN",
} as const;

export type WebAuthnError = typeof WebAuthnErrors[keyof typeof WebAuthnErrors];

export interface WebAuthnSignInProps {
    onClick(): void;
    onError(errorMessage: WebAuthnError): void;
    setLoading(bool: boolean): void;
    hideButtonText?: boolean;
}

export function WebAuthnSignIn({ onClick, onError, setLoading, hideButtonText }: WebAuthnSignInProps) {
    const {
        setCognitoUser,
        webauthnRegisteredOnDevice,
        username,
        setLoginFormState,
    } = useLoginStore();
    const webauthnIcons = useWebauthnIcons();
    const webauthnStrings = useWebauthnStrings();

    async function onClickWebAuthNButton() {
        onClick();
        if (!window.PublicKeyCredential) { // Client not capable of webauthn
            onError(WebAuthnErrors.BIOMETRICS_NOT_SUPPORTED);
        }
        // call webauthn auth apis
        setLoading(true);
        const hostName = window.location.hostname;
        try {
            const clientMetadata = {
                preferredAuthMethod: 'WebAuthN',
                executionContextId: crypto.randomUUID(),
                relyingPartyId: hostName,
            };
            const intermediateUser: ChallengeUser = await Auth.signIn(username, undefined, clientMetadata);
            const answer = await GetWebauthnChallengeAnswer(intermediateUser);
            const result = await Auth.sendCustomChallengeAnswer(intermediateUser, answer, clientMetadata);
            if (result instanceof CognitoUser) {
                setCognitoUser(result as IdPrismUser);
                setLoading(false);
                setLoginFormState(LOGIN_FORM_STATES.SPINNER);
            } else {
                setLoading(false);
                onError(WebAuthnErrors.INCORRECT_WEBAUTHN);
            }
        } catch (error) {
            const authError = error as Error;
            console.log('Auth Error:', authError);
            if (authError.name === FAILED_TO_GET_WEBAUTHN_CHALLENGE_ANSWER) {
                onError(WebAuthnErrors.FAILED_TO_GET_WEBAUTHN_CHALLENGE_ANSWER);
            } else if ((authError.name === NOT_ALLOWED_ERROR && !webauthnRegisteredOnDevice) || authError.message === 'Incorrect username or password.' || authError.name === LAMBDA_VALIDATION_EXCEPTION) {
                onError(WebAuthnErrors.NO_CREDENTIALS_REGISTERED);
            } else if (authError.message === 'Username cannot be empty') {
                onError(WebAuthnErrors.NO_USERNAME_ENTERED);
            } else if (authError.name === INVALID_PARAMETER_EXCEPTION) {
                onError(WebAuthnErrors.BIOMETRICS_NOT_SUPPORTED);
            }
            else {
                onError(WebAuthnErrors.UNKNOWN);
            }
            setLoading(false);
        }
    }
    useEffect(() => {
        if (webauthnRegisteredOnDevice) {
            onClickWebAuthNButton();
        }
    }, [webauthnRegisteredOnDevice]);

    const webAuthnImage = <img
        width='20%'
        aria-label={t('resources:useBiometricToLogIn', webauthnStrings)!}
        src={webauthnIcons}
        onClick={onClickWebAuthNButton}
        alt='BiometricsLink' />

    if (hideButtonText) {
        return webAuthnImage;
    }

    return <Button
        icon={<img width={90} height={35} aria-label={t('resources:useWebAuthNToLogIn') as string} src={webauthnIcons} alt='Web Authn Login Icons' />}
        data-testid="webauthn-signin-button"
        onClick={onClickWebAuthNButton}
        variant={ButtonVariant.Tertiary}
        iconPosition={ButtonIconPosition.Trailing}
        size={ButtonSize.Small}
        disabled={!username}
        style={{ width: '100%' }}
    >
        {t('resources:signInWith')} {t('resources:Login-with-SecurityKey', webauthnStrings)}
    </Button>
}