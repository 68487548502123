import { Locale, StageConfig } from './globals/types';

const translations: {[key: string]: Locale[]} = {
    "tacs": [
        {
            "name": "English (US)",
            "value": "en-US",
            "puff": "TaCS-en-US"
        }
    ],
    "tps": [
        {
            "name": "English (US)",
            "value": "en-US",
            "puff": "TPS-en-US"
        }
    ],
    "default": [
        {
            "name": "English (US)",
            "value": "en-US",
            "puff": "IdPrismCupidLogin-en-US"
        }
    ]
}

const stageConfig: StageConfig = {
    "test": {
            "test": {
                "userpoolId": "us-east-1_vsYk1mhpo",
                "clientId": "4r6vscn27ksd694q3qck2uudgj",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false
            },
        },
        "development": {
            "webauthntestalpha": {
                "userpoolId": "us-east-1_lyN5WuCQi",
                "clientId": "7upfvschpnak8vr4efto8t9naa",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": true,
                "webauthnSupport": "OPTIONAL"
            },
            "pets": {
                "userpoolId": "us-east-1_hPpkOkAY5",
                "clientId": "2n3906foes9h9enaaeunhndk9k",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": true,
                "links": {
                    "selfSignUp": " https://vendor-signup.pets.nacf.amazon.dev/"
                },
            },
            "atoz": {
                "userpoolId": "us-east-1_a6D5Yinos",
                "clientId": "299l9686fpa257tlmdjjvip1i4",
                "region": "us-east-1",
                "links": {
                    "getHelp": "https://atoz-login.integ.amazon.com/help#alumni",
                    "welcomePage": "https://atoz-login.integ.amazon.com/alumni-contact-select",
                    "privacyPolicy": "https://atoz-login.integ.amazon.com/privacy-policy",
                    "selfSignUp": "https://atoz-login.integ.amazon.com/create-account",
                },
                "template": "atoz",
                "emailMFA": false,
                "webauthnSupport": "OPTIONAL"
            },
            "webauthndemo": {
                "userpoolId":"us-east-1_cplvNevJR",
                "clientId":"36qqln873b835qfdn2tilbd44h",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false,
                "webauthnSupport": "OPTIONAL"
            },
            "tacs": {
                "userpoolId": "us-east-1_ML1t63WPu",
                "clientId": "3171f0hn692nrhbg1j0923h8jf",
                "region": "us-east-1",
                "emailMFA": true,
                "template": "default"
            },
            "tps": {
                "userpoolId": "us-east-1_VOLy1RtpR",
                "clientId": "2gga3r8p56i4uoguo17jqirb79",
                "region": "us-east-1",
                "emailMFA": true,
                "template": "default",
                "links": {
                    "getHelp": "https://tpspublic.s3.amazonaws.com/Third+Party+Portal+FAQs+Overall.pdf",
                    "termsOfUse": "https://irm.a2z.com/svdp?id=svdp_terms_of_use"
                }
            },
            "clarizen": {
                "userpoolId": "us-east-1_6rev6RUyp",
                "clientId": "7a8h2uao9h7kl5r8dtts547tjj",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false,
                "oauth": {
                    "domain": "tenant-gref-clarizen-na-egqnse.auth.us-east-1.amazoncognito.com",
                    "providers": [
                        "Login-with-SecurityKey",
                        "Password"
                    ]
                }
            },
            "test": {
                "userpoolId": "us-east-1_CqcA02xMb",
                "clientId": "7jrah7d1odbue369n1893l28nb",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false,
                "oauth": {
                    "domain": "tenant-donotdeleteserviceadmin-cupidtestpool-na-crvkho.auth.us-east-1.amazoncognito.com",
                    "providers": [
                        "Login-with-SecurityKey",
                        "Authgard"
                    ]
                }
            },
            "testclientc": {
                "userpoolId": "us-east-1_7yCo4HYls",
                "clientId": "4j36c98cvac5uhdrv5h9hhamk6",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false,
                "oauth": {
                    "domain": "tenant-testclientc-noauthtriggers-na-kufaty.auth.us-east-1.amazoncognito.com",
                    "providers": [
                        "Password",
                        "TestClientB"
                    ]
                }
            },
            "testclientb": {
                "userpoolId": "us-east-1_25j2Zoyda",
                "clientId": "35lj7744thct8n5oja7bfcev49",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false,
                "webauthnSupport": "OPTIONAL",
                "oauth": {
                    "domain": "tenant-testclientb-testingpoolb-na-kqlmgm.auth.us-east-1.amazoncognito.com",
                    "providers": [
                        "Password",
                        "Login-with-SecurityKey"
                    ]
                }
            },
            "globalmilephloxapp": {
                "userpoolId": "us-east-1_In7rvdPEY",
                "clientId": "40dcg7im41nt2uglvtm01jnbnl",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false
            },
        },
        "alpha": {
            "atoz": {
                "userpoolId": "us-east-1_a6D5Yinos",
                "clientId": "299l9686fpa257tlmdjjvip1i4",
                "region": "us-east-1",
                "links": {
                    "getHelp": "https://atoz-login.integ.amazon.com/help#alumni",
                    "welcomePage": "https://atoz-login.integ.amazon.com/alumni-contact-select",
                    "privacyPolicy": "https://atoz-login.integ.amazon.com/privacy-policy",
                    "selfSignUp": "https://atoz-login.integ.amazon.com/create-account",
                },
                "template": "atoz",
                "emailMFA": false,
                "webauthnSupport": "OPTIONAL"
            },
            "tacs": {
                "userpoolId": "us-east-1_ML1t63WPu",
                "clientId": "3171f0hn692nrhbg1j0923h8jf",
                "region": "us-east-1",
                "emailMFA": true,
                "template": "default"
            },
            "tps": {
                "userpoolId": "us-east-1_VOLy1RtpR",
                "clientId": "2gga3r8p56i4uoguo17jqirb79",
                "region": "us-east-1",
                "emailMFA": true,
                "template": "default",
                "links": {
                    "getHelp": "https://tpspublic.s3.amazonaws.com/Third+Party+Portal+FAQs+Overall.pdf",
                    "termsOfUse": "https://irm.a2z.com/svdp?id=svdp_terms_of_use"
                }
            },
            "test": {
                "userpoolId": "us-east-1_vsYk1mhpo",
                "clientId": "4r6vscn27ksd694q3qck2uudgj",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false
            },
            "tc1": {
                "userpoolId": "us-east-1_CqcA02xMb",
                "clientId": "7jrah7d1odbue369n1893l28nb",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false
            }
        },
        "beta": {
            "atoz": {
                "userpoolId": "us-east-1_a6D5Yinos",
                "clientId": "299l9686fpa257tlmdjjvip1i4",
                "region": "us-east-1",
                "links": {
                    "getHelp": "https://atoz-login.integ.amazon.com/help#alumni",
                    "welcomePage": "https://atoz-login.integ.amazon.com/alumni-contact-select",
                    "privacyPolicy": "https://atoz-login.integ.amazon.com/privacy-policy",
                    "selfSignUp": "https://atoz-login.integ.amazon.com/create-account",
                },
                "template": "atoz",
                "emailMFA": false,
                "webauthnSupport": "OPTIONAL"
            },
            "tacs": {
                "userpoolId": "us-east-1_KTACKXP7a",
                "clientId": "3gmq0iqoc7dpv20tiurjmt1rc7",
                "region": "us-east-1",
                "emailMFA": true,
                "template": "default"
            },
            "tps": {
                "userpoolId": "us-east-1_VOLy1RtpR",
                "clientId": "2gga3r8p56i4uoguo17jqirb79",
                "region": "us-east-1",
                "emailMFA": true,
                "template": "default",
                "links": {
                    "getHelp": "https://tpspublic.s3.amazonaws.com/Third+Party+Portal+FAQs+Overall.pdf",
                    "termsOfUse": "https://irm.a2z.com/svdp?id=svdp_terms_of_use"
                }
            },
            "test": {
                "userpoolId": "us-east-1_VOLy1RtpR",
                "clientId": "2gga3r8p56i4uoguo17jqirb79",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false
            },
            "audibleiconik": {
                "userpoolId": "us-east-1_Sl3nQ9DBc",
                "clientId": "15af33k8kfrac5hkcdlu136j5n",
                "region": "us-east-1",
                "emailMFA": false,
                "template": "default"
            },
            "tc1": {
                "userpoolId": "us-east-1_CqcA02xMb",
                "clientId": "7jrah7d1odbue369n1893l28nb",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false
            },
            "pets": {
                "userpoolId": "us-east-1_hPpkOkAY5",
                "clientId": "2n3906foes9h9enaaeunhndk9k",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": true,
                "links": {
                    "selfSignUp": " https://vendor-signup.pets.nacf.amazon.dev/"
                },
            },
            "dds": {
                "userpoolId": "us-east-1_dM86jpclR",
                "clientId": "75rrd1sr0lln3dhrvofeds27n8",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false
            },
            "testclienta": {
                "userpoolId": "us-east-1_6P8xiV9qu",
                "clientId": "68lm0kkfu6jmo2taiof1g8c9up",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false,
                "oauth": {
                    "domain": "tenant-testcustomerclient-testingpoola-na-djlpuf.auth.us-east-1.amazoncognito.com",
                    "providers": [
                        "Password",
                        "Login-with-SecurityKey"
                    ]
                }
            },
            "testclientb": {
                "userpoolId": "us-east-1_25j2Zoyda",
                "clientId": "35lj7744thct8n5oja7bfcev49",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false,
                "oauth": {
                    "domain": "tenant-testclientb-testingpoolb-na-kqlmgm.auth.us-east-1.amazoncognito.com",
                    "providers": [
                        "Password",
                        "Login-with-SecurityKey"
                    ]
                }
            },
            "testclientc": {
                "userpoolId": "us-east-1_7yCo4HYls",
                "clientId": "4j36c98cvac5uhdrv5h9hhamk6",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false,
                "oauth": {
                    "domain": "tenant-testclientc-noauthtriggers-na-kufaty.auth.us-east-1.amazoncognito.com",
                    "providers": [
                        "Password",
                        "TestClientB",
                        "Login-with-SecurityKey"
                    ]
                }
            },
            "powercow": {
                "userpoolId": "us-east-1_LSpJnkPYS",
                "clientId": "bs88d0jb6a83rqc5cp0odlhml",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false
            },
            "asvgitlab": {
                "userpoolId": "us-east-1_5SPNPlN0u",
                "clientId": "7efa8bcjvj4o12kesj39hkit0b",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": true
            },
            "globalmilephloxapp": {
                "userpoolId": "us-east-1_In7rvdPEY",
                "clientId": "40dcg7im41nt2uglvtm01jnbnl",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": true,
                "webauthnSupport": "OPTIONAL"
            },
            "quicknotate": {
                "userpoolId": "us-east-1_AU41iaUJa",
                "clientId": "6vvhpkomfetubnnvi64jrp6tji",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false
            },
            "cxbtauth": {
                "userpoolId": "us-east-1_WzXSAFXvr",
                "clientId": "3as2i50155j1ptuarlmuld1mjt",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false,
                "oauth" : {
                    "domain": "tenant-benchmarking-beta-na-jirvvw.auth.us-east-1.amazoncognito.com",
                    "providers": [
                        "Login-with-SecurityKey",
                        "Password",
                        "AmazonSSO"
                    ]
                }
            },
            "clarizenops": {
                "userpoolId": "us-east-1_XYpw6Ymcb",
                "clientId": "7563qi3q008o6asgfub32smjvv",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false,
                "oauth": {
                    "domain": "tenant-gref-clarizenops-na-apzzaj.auth.us-east-1.amazoncognito.com",
                    "providers": [
                        "AmazonSSO"
                    ]
                }
            },
            "clarizendefault": {
                "userpoolId": "us-east-1_NyatwxKSA",
                "clientId": "4lbtd048chi94816j1sa13ll65",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false,
                "oauth": {
                    "domain": "tenant-gref-clarizen-na-renolk.auth.us-east-1.amazoncognito.com",
                    "providers": [
                        "Login-with-SecurityKey"
                    ]
                }
            },
            "opstechitgate": {
                "userpoolId": "us-east-1_4HHoSE1Ck",
                "clientId": "4rhf7qeeoj64ipg2i8ajismbbt",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false,
                "oauth": {
                    "domain": "tenant-opstechit-gate-na-iymvbx.auth.us-east-1.amazoncognito.com",
                    "providers": [
                        "Login-with-SecurityKey",
                        "Password"
                    ]
                }
            },
            "rmeapm": {
                "userpoolId": "us-east-1_T4zIXpNAU",
                "clientId": "4ne5q8q4uksu6nbc8vsc3c95s1",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": true
            },
            "arplmdev": {
                "userpoolId": "us-east-1_YpThVYhhJ",
                "clientId": "n681hmdgl39utd0k40nd0bfsd",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false,
                "webauthnSupport": "OPTIONAL"
            },
            "d3ploy": {
                "userpoolId": "us-east-1_MMWYeXUcN",
                "clientId": "5vnk7qblanpl69hgp1hsf4vqne",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": true
            }
        },
        "beta-eu": {
            "test": {
                "userpoolId": "eu-west-2_jhsbbtsMi",
                "clientId": "7h44s1gnol9d6mn5tmd5q7sq76",
                "region": "eu-west-2",
                "template": "default",
                "emailMFA": true
            },
            "faastreg": {
                "userpoolId": "eu-west-2_PF0tapnCO",
                "clientId": "677a5s3pr0kui4d3jt34beog1f",
                "region": "eu-west-2",
                "template": "default",
                "emailMFA": true
            },
            "faastauth": {
                "userpoolId": "eu-west-2_PF0tapnCO",
                "clientId": "7kv94vdenrtieharfa5p5qnov9",
                "region": "eu-west-2",
                "template": "default",
                "emailMFA": false,
                "oauth": {
                    "domain": "tenant-faast-default-eu-gmjoty.auth.eu-west-2.amazoncognito.com",
                    "providers": [
                        "Authgard"
                    ]
                }
            }
        },
        "beta-ap": {
            "test": {
                "userpoolId": "",
                "clientId": "",
                "region": "",
                "template": "default",
                "emailMFA": false
            },
            "faastreg": {
                "userpoolId": "ap-south-1_GpkaKoCK0",
                "clientId": "375shrof3s3h0p5l8qcno6kn95",
                "region": "ap-south-1",
                "template": "default",
                "emailMFA": true
            },
            "faastauth": {
                "userpoolId": "ap-south-1_GpkaKoCK0",
                "clientId": "789dfts0iae1ucs3ol79f0rv2m",
                "region": "ap-south-1",
                "template": "default",
                "emailMFA": false,
                "oauth": {
                    "domain": "tenant-faast-default-ap-pigcdf.auth.ap-south-1.amazoncognito.com",
                    "providers": [
                        "Authgard"
                    ]
                }
            },
            "sify": {
                "userpoolId": "ap-south-1_bxgVxOOdT",
                "clientId": "3j1u4lmeoe419d6mbkujfv9vpa",
                "region": "ap-south-1",
                "template": "default",
                "emailMFA": false
            }
        },
        "gamma": {
            "atoz": {
                "userpoolId": "",
                "clientId": "",
                "region": "",
                "links": {
                    "getHelp": "https://atoz-login.integ.amazon.com/help#alumni",
                    "welcomePage": "https://atoz-login.integ.amazon.com/alumni-contact-select",
                    "privacyPolicy": "https://atoz-login.integ.amazon.com/privacy-policy",
                    "selfSignUp": "https://atoz-login.integ.amazon.com/create-account",
                },
                "template": "atoz",
                "emailMFA": false,
                "webauthnSupport": "OPTIONAL"
            },
            "test": {
                "userpoolId": "",
                "clientId": "",
                "region": "",
                "template": "default",
                "emailMFA": false
            }
        },
        "prod-na": {
            "atoz": {
                "userpoolId": "us-east-1_qb4RRYyER",
                "clientId": "6b4il5kbhc5rt5jkvajfef4s5c",
                "region": "us-east-1",
                "links": {
                    "getHelp": "https://atoz-login.amazon.work/help#alumni",
                    "welcomePage": "https://atoz-login.amazon.work/alumni-contact-select",
                    "privacyPolicy": "https://atoz-login.amazon.work/privacy-policy",
                    "selfSignUp": "https://atoz-login.amazon.work/create-account",
                },
                "template": "atoz",
                "emailMFA": false,
                "webauthnSupport": "OPTIONAL",
            },
            "tps": {
                "userpoolId": "us-east-1_MeEFeI2kM",
                "clientId": "489s6n58trff9oo99oauajrdjl",
                "region": "us-east-1",
                "emailMFA": true,
                "template": "default",
                "links": {
                    "getHelp": "https://tpspublic.s3.amazonaws.com/Third+Party+Portal+FAQs+Overall.pdf",
                    "termsOfUse": "https://irm.a2z.com/svdp?id=svdp_terms_of_use"
                }
            },
            "tacs": {
                "userpoolId": "us-east-1_lecR70bE0",
                "clientId": "3kjc6stiut6jkeddni59hdmu2s",
                "region": "us-east-1",
                "emailMFA": true,
                "template": "default"
            },
            "quicknotate": {
                "userpoolId": "us-east-1_8rEPcVCNq",
                "clientId": "4ssa07vtmfdkimn34h5s2pkp9k",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false
            },
            "cxbtauth": {
                "userpoolId": "us-east-1_WE3XbCgaM",
                "clientId": "6fc4s55uum962dvkor5t57vmge",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false,
                "oauth": {
                    "domain": "tenant-cxbtauth-cxbtexternalvendor-na-wgoxkl.auth.us-east-1.amazoncognito.com",
                    "providers": [
                        "Login-with-SecurityKey",
                        "Password",
                        "AmazonSSO"
                    ]
                }
            },
            "clarizenops": {
                "userpoolId": "us-east-1_KN9myQ8M7",
                "clientId": "5ic9iud225qakpip1jj021nfdo",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false,
                "oauth": {
                    "domain": "tenant-gref-clarizenops-na-agvfii.auth.us-east-1.amazoncognito.com",
                    "providers": [
                        "AmazonSSO"
                    ]
                }
            },
            "clarizendefault": {
                "userpoolId": "us-east-1_qTkHsVOBF",
                "clientId": "6dif0hk5lhe0qma5q94njqc9pb",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false,
                "oauth": {
                    "domain": "tenant-gref-clarizen-na-cdakst.auth.us-east-1.amazoncognito.com",
                    "providers": [
                        "Login-with-SecurityKey"
                    ]
                }
            },
            "opstechitgate": {
                "userpoolId": "us-east-1_UKdp3rmtx",
                "clientId": "5tg76h10547ms42vlpis856bbs",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": false,
                "oauth": {
                    "domain": "tenant-opstechit-gate-na-dllgil.auth.us-east-1.amazoncognito.com",
                    "providers": [
                        "Login-with-SecurityKey",
                        "Password"
                    ]
                }
            },
            "audibleiconik": {
                "userpoolId": "us-east-1_v3llwogzg",
                "clientId": "2evk5cbarg4u6888e5ree62m4v",
                "region": "us-east-1",
                "emailMFA": false,
                "template": "default"
            },
            "pets": {
                "userpoolId": "us-east-1_FwhgkWlU7",
                "clientId": "ll06m67evuj36jdcv3c7r3220",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": true,
                "links": {
                    "selfSignUp": "https://vendor-signup.pets.nacf.amazon.dev/"
                },
            },
            "globalmilephloxapp": {
                "userpoolId": "us-east-1_EPRX89DUa",
                "clientId": "76qg6dk9iohngjj8en9htobe77",
                "region": "us-east-1",
                "template": "default",
                "emailMFA": true
            }
        },
        "prod-eu": {
            "test": {
                "userpoolId": "eu-west-2_wk9fzSZic",
                "clientId": "6e0fhgmp8g189m7jkabh7gfimb",
                "region": "eu-west-2",
                "template": "default",
                "emailMFA": true
            },
            "faastreg": {
                "userpoolId": "eu-west-2_XHXjaXotH",
                "clientId": "5pmu78u55tsh45dg5lvi6nc5rv",
                "region": "eu-west-2",
                "template": "default",
                "emailMFA": true
            },
            "faastauth": {
                "userpoolId": "eu-west-2_XHXjaXotH",
                "clientId": "5sn5iufblrjjihdbihurfu4r7p",
                "region": "eu-west-2",
                "template": "default",
                "emailMFA": false,
                "oauth": {
                    "domain": "tenant-faast-default-eu-bkjzpd.auth.eu-west-2.amazoncognito.com",
                    "providers": [
                        "Authgard"
                    ]
                }
            }
        },
        "prod-ap": {
            "test": {
                "userpoolId": "",
                "clientId": "",
                "region": "",
                "template": "default",
                "emailMFA": false
            },
            "faastreg": {
                "userpoolId": "ap-south-1_i1aYSJtMu",
                "clientId": "7bekgamb250uilqb9v9v2sgrv4",
                "region": "ap-south-1",
                "template": "default",
                "emailMFA": true
            },
            "faastauth": {
                "userpoolId": "ap-south-1_i1aYSJtMu",
                "clientId": "4pf1huv8eeqo5gd084sa5hvhni",
                "region": "ap-south-1",
                "template": "default",
                "emailMFA": false,
                "oauth": {
                    "domain": "tenant-faast-default-ap-ypdcfx.auth.ap-south-1.amazoncognito.com",
                    "providers": [
                        "Authgard"
                    ]
                }
            },
            "sify": {
                "userpoolId": "ap-south-1_jvnYLV2qH",
                "clientId": "6b0gk68veq0rjiqf0cldpds1vk",
                "region": "ap-south-1",
                "template": "default",
                "emailMFA": true
            }
        }
};

export default {
    translations,
    stage: stageConfig
}